import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { RequestFormModel } from 'src/app/requests/request-form.model';
import { LoggedInUserInfoModel } from 'src/app/site-permissions/loggedin-user-access.model';

import { VendorResponseMasterModel } from '../../vendor-response-master.model';
import { VendorResponseModel } from '../../vendor-response.model';
import { VendorUpdateCommonComponent } from '../../request-vendor-form/vendor-update-common.component';
import { VendorResponseFormData } from '../../data/vendor-response-form.data';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { VendorApiSubmit } from '../../data/vendor-api-submit.data';
import { BulkDrayageVendorResponseFormData } from '../../data/bulk-drayage-vendor-response-form.data';
import { BulkDrayageVendorResponseModel } from '../../bulk-drayage-vendor-response.model';
import { ISODryageTruckFormModel } from 'src/app/requests/iso-dryage-truck.model';
import { ISoDryageTruckRequestDetails } from 'src/app/requests/data/formdata/iso-dryage-truck-request-details.data';
import * as moment from 'moment';




@Component({
  selector: 'app-bulk-drayage-vendor-sa',
  templateUrl: '..\\bulk-drayage-details-vendor-template.html',
  styleUrls: ['..\\bulk-drayage-details-vendor-template.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel,
    VendorResponseMasterModel,
    ConfirmationService,
    { provide: RequestFormModel, useClass: ISODryageTruckFormModel },
    { provide: VendorResponseModel, useClass: BulkDrayageVendorResponseModel }

  ]
})
export class BulkDrayageVendorUpdateComponentSA  extends VendorUpdateCommonComponent  implements OnInit {

  
translate:TranslateService;
spinner:NgxSpinnerService;

  constructor(private userAccessModel: LoggedInUserInfoModel, private requestModel: RequestFormModel,
    private vendorMaster: VendorResponseMasterModel, private vendorModel: VendorResponseModel,_translate:TranslateService,
    private cService: ConfirmationService, private toast: ToastrService, private router: Router,private _spinner: NgxSpinnerService) {
      super(_translate,_spinner);
      this.translate=_translate;
      this.spinner=_spinner;
     }

  ngOnInit() {
   this.requestData = new ISoDryageTruckRequestDetails();
   this.vendorObj = new BulkDrayageVendorResponseFormData();
   
    this.validateloggedInUser(this.userAccessModel,this.router);
    this.retrievevendorMaster(this.vendorMaster);
    this.retrieveVendorUserMaster(this.vendorMaster);
    this.retrievevendorCurrency(this.vendorMaster);
    this.retrieveRequestDetails(this.transportType, this.requestId, this.requestModel);
    
  }
  onRetrieveRequestCompleted()
  {    
    this.retrieveVendorResponse(this.vendorModel);
  }

 onRetrieveVendorResponseCompleted()
 {  
  this.reasonForSelection=((this.requestData)as ISoDryageTruckRequestDetails).reasonForSelection;
  this.rateReqCalData=((this.requestData)as ISoDryageTruckRequestDetails).rateReqCalData;
  this.rateReqCalFileName=((this.requestData)as ISoDryageTruckRequestDetails).rateReqCalFileName;
  this.rateReqCalFileExtn=((this.requestData)as ISoDryageTruckRequestDetails).rateReqCalFileExtn;

 }

  
  vendorSelected(rowData:any) {
    rowData.validVendor=true;
    rowData.selectedVendorUser=[];    
    rowData.vendorUser=this.VendorUsersList.filter(user=>user.VendorId==rowData.vendorId);
  }

  
   onfilterVendorName(rowData: any) {
    this.vendorsData.forEach((v:any) => {
        (v as BulkDrayageVendorResponseFormData).disable = true;
        if (v.vendorId == rowData.vendorId) {
          if(this.vendorsList.filter(v1 => v1.value == rowData.vendorId).length > 0)
            v.vendorName = this.vendorsList.filter(v1 => v1.value == rowData.vendorId)[0].label;
          else
          v.vendorName=rowData.vendorName
        }
        else if(v.vendorName==''){
          v.vendorName=this.vendorsList.find(x=>x.value==v.vendorId)!.label;
        }
    });
}
 CreateNewVendorResponseRow() {  
  let vendorObj=new BulkDrayageVendorResponseFormData();
    vendorObj.currencyType=this.requestData.currencyId;
  this.onCreateNewVendorResponseRow(vendorObj);
  
  }
  addVendor(rowData:any) {
    let vendorObj=new BulkDrayageVendorResponseFormData();
    vendorObj.currencyType=this.requestData.currencyId;
     this.onAddVendor(rowData,this.toast,vendorObj)
  }
  removeVendor(rowData:any) {
    this.onRemoveVendor(rowData);
  }

  openDeleteVendorPopup(rowData:any) {
    this.onOpenDeleteVendorPopup(rowData,this.cService)
  }

   bindVendorResponseResult(element: any) {
   
    return element  as BulkDrayageVendorResponseFormData;
  }

  onSelectionChange(rData:any,field:any) {
    this.vendorsData.forEach((v:any)=>{
        if (v.vendorId == rData.vendorId && field=="isSelected") {
          v.isSelected = !v.isSelected;
          rData.rankAssigned=v.isSelected?rData.rankAssigned:0;
        }
    })
  }
  isVendorsDueDatePassed(){
    this.enableAwardingSubmit=true;
    this.enableVendorInputMode=false;
    let isdueDategreater:boolean=false;
    this.vendorsData.forEach(ven=>{
      if(ven.dueDate!==undefined && ven.dueDate!=null){       
        if(moment(moment(this.minDateValue).format('YYYY-MM-DD')).diff(moment(moment(ven.dueDate).format('YYYY-MM-DD')))<=0) {
          isdueDategreater=true;
          this.enableVendorInputMode=true;
        }
      }
      else if(ven.bidAmount==0){
        isdueDategreater=true;
        this.enableVendorInputMode=true;
      }
      else{      
        this.enableVendorInputMode=true;
      }      
    })
    this.selectMinBidAmount(isdueDategreater)
   }

   selectMinBidAmount(isdueDategreater:boolean){
    if(isdueDategreater){
      this.enableAwardingSubmit=false;
    }
    if(this.enableAwardingSubmit){      
      let minBidAmount:any=0;
      minBidAmount=this.calculateMinimumBidAmount(minBidAmount);
      
      if(this.requestData.requestStatusId!==7 && minBidAmount){
        this.vendorsData.forEach(ven=>{
          if(ven.bidAmount==minBidAmount){
            ven.isSelected=true
          }          
        })
      }      
    }
   }

   calculateMinimumBidAmount(minBidAmount:any){
    this.vendorsData.forEach(ven=>{        
      if(ven.bidAmount>0){
        minBidAmount=minBidAmount==0?ven.bidAmount:minBidAmount;
        minBidAmount = minBidAmount>ven.bidAmount?ven.bidAmount:minBidAmount;
      }      
    }) 
    return minBidAmount;
   }

   rearrangeRanksonRemove(rowData:any){
    this.ranks = [];     

    for(let vd of this.vendorsData){
       //managing vendor first rank
      if(vd.rankAssigned> rowData.rankAssigned){
        vd.rankAssigned = vd.rankAssigned - 1;
      }    

      let rObj = {
        value: this.ranks.length + 1,
        label: (this.ranks.length + 1).toString()
      };
      this.ranks.push(rObj);      
    }
  } 


  override calculateDueDate(d:any){
    let todaysDate=new Date();
    if(todaysDate.getDay()==0 || todaysDate.getDay()==1 || todaysDate.getDay()==2){
            d=new Date(todaysDate.setDate(todaysDate.getDate()+3));
     }
    else if(todaysDate.getDay()==3 || todaysDate.getDay()==4 || todaysDate.getDay()==5){
            d=new Date(todaysDate.setDate(todaysDate.getDate()+5));
    }
    else{
            d=new Date(todaysDate.setDate(todaysDate.getDate()+4));
    }
    return d;
  }


  notifyVendors(){
    this.spinner.show()
    let unsavedVendors:boolean=false;
    let noUserSelected:boolean=false;
    this.selectedVendors.forEach(vendor=>{      
      if(!vendor.responseId){
        unsavedVendors=true;
      }
    })
    if(unsavedVendors){
      this.spinner.hide();
      this.toast.warning(this.translate.instant("UPDATE_VENDORS_BEFORE_NOTIFY"),this.translate.instant("WARNING"))
      return false;
    }else{

    this.selectedVendors.forEach(vendor=>{
      if(vendor.dueDate==undefined){
        vendor.dueDate=formatDate(this.calculateDueDate(vendor.dueDate),'yyyy-MM-dd','en-Us')
      }
      if(!vendor.selectedVendorUser.length){
        noUserSelected=true;
      }      
    })
    if(noUserSelected){
      this.spinner.hide();
      this.toast.warning(this.translate.instant("SELECT_ATLEAST_ONE_VENDOR"),this.translate.instant("WARNING"))
      return false;
    }
    this.selectedVendors=this.vendorModel.validateVendor(this.selectedVendors);
    this.selectedVendors=this.vendorModel.validateRank(this.ranks,this.selectedVendors);
    let  _vendorData=this.selectedVendors as BulkDrayageVendorResponseFormData[];
      let invalidData = _vendorData .filter((v:any) => !v.validVendor || !v.validBidAmount  || !v.validComments  || !v.validCurrency || !v.validDueDate);
      
      if (invalidData.length > 0) {
        this.spinner.hide()
        return false;
      }
      let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
      apisubmitdata.vendorsData=this.selectedVendors as BulkDrayageVendorResponseFormData[];
      apisubmitdata.requestId=this.requestId;
      apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
      apisubmitdata.selectedReason=this.reasonForSelection;
      apisubmitdata.isconfirmed=false;
      apisubmitdata.requestStatus=this.requestData.requestStatusId;
      apisubmitdata.requestorMail=this.requestData.requesterMailId;
      apisubmitdata.transportType=this.transportType;
      apisubmitdata.modifiedBy=this.userAccessPermission.userId;

      this.vendorModel.notifyVendorResponse(apisubmitdata)
      .subscribe({
        next:notifyvendorResponse => {
        this.selectedVendors = [];
        this.vendorsData=[];
        this.retrieveVendorResponse(this.vendorModel)
        this.spinner.hide();
        this.toast.success(this.translate.instant("VENDOR_USERS_NOTIFIED_SUCCESSFULLY"),this.translate.instant("SUCCESS"));        
        
      },
        error:error => {
          this.spinner.hide();
          this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"));
        }
        });
  
        return true
      }
      
 }

  submitBidByVendor(){
    this.spinner.show()
    if(!this.validateSubmitByVendor()){
      return;
    }
    let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
    apisubmitdata.vendorsData=this.vendorsData as BulkDrayageVendorResponseFormData[];
    apisubmitdata.requestId=this.requestId;
    apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
    apisubmitdata.selectedReason=this.reasonForSelection;
    apisubmitdata.isconfirmed=false;
    apisubmitdata.requestStatus=this.requestData.requestStatusId;
    apisubmitdata.requestorMail=this.requestData.requesterMailId;
    apisubmitdata.transportType=this.transportType;
    apisubmitdata.modifiedBy=this.userAccessPermission.userId;


    this.vendorModel.submitBidByVendor(apisubmitdata)
    .subscribe({
      next:(response:any)=>{    
      if(response=='SUCCESS'){
        this.vendorsData=[]
        this.retrieveVendorResponse(this.vendorModel)
        this.spinner.hide()
        this.toast.success(this.translate.instant("BID_SUBMIT_SUCCESFUL"),this.translate.instant("SUCCESS"));
      }      
      else{
        this.spinner.hide()
        this.toast.warning(this.translate.instant(response),"warning")
      }
      
    },
    error:error=>{
      this.spinner.hide()
      this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"));
    }
  }
    )
  }

  validateSubmitByVendor(){
    if(!this.vendorsData[0].bidAmount){       
      this.spinner.hide()
      this.toast.warning(this.translate.instant("VALID_BID_AMOUNT_FOR_TRUCKRATE"),this.translate.instant("WARNING"));
      return false;
    }
    return true;
  }

  updateVendors() {
    this.spinner.show()
    if (!this.clearVendors) {
      this.vendorsData = this.vendorModel.validateVendor(this.vendorsData);
      let  _vendorData=this.vendorsData as BulkDrayageVendorResponseFormData[];
      let invalidData = _vendorData .filter((v:any) => !v.validVendor || !v.validBidAmount  || !v.validComments  || !v.validCurrency || !v.validDueDate);
      if (invalidData.length > 0) {
        this.spinner.hide()
        return ;
      }   
        
    }
    this.vendorsData.forEach(vendor=>{
      if(vendor.dueDate==undefined){
        vendor.dueDate=formatDate(this.calculateDueDate(vendor.dueDate),'yyyy-MM-dd','en-Us')
      }
      vendor.rankAssigned=vendor.rankAssigned??0;          
    })

    let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
      apisubmitdata.vendorsData=this.vendorsData as VendorResponseFormData[];
      apisubmitdata.requestId=this.requestId;
      apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
      apisubmitdata.selectedReason=this.reasonForSelection;
      apisubmitdata.isconfirmed=false;
      apisubmitdata.requestStatus=this.requestData.requestStatusId;
      apisubmitdata.requestorMail=this.requestData.requesterMailId;
      apisubmitdata.transportType=this.transportType;
      apisubmitdata.modifiedBy=this.userAccessPermission.userId;
    
    this.vendorModel.submitvendorResponse(apisubmitdata,"","","")
      .subscribe({
        next:(submitvendorResponse:any) => {  
        this.refresh.emit('update');
        this.vendorsData = [];
        this.selectedVendors=[];
        this.ranks=[]
        this.spinner.hide()
        this.retrieveRequestDetails(this.transportType,this.requestId,this.requestModel);        
        if(submitvendorResponse=='SUCCESS')
        this.toast.success(this.translate.instant("VENDORS_UPDATE_SUCCESSFUL"),this.translate.instant("SUCCESS"));
        else{
          this.toast.warning(this.translate.instant(submitvendorResponse),this.translate.instant("WARNING"))
        }       
      },
        error:error => {
          this.spinner.hide()
          this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"));
        }
        });       

  }  

  canelSubmit() {
    this.spinner.show()
    this.vendorsData = [];
    this.selectedVendors=[];
    this.ranks=[];
    this.retrieveVendorResponse(this.vendorModel);
    this.spinner.hide();  

  }

  override cancelvendorSubmit(){   
    this.router.navigateByUrl('/request-list')
 }

  openCofirmPopup() {
    this.cService.confirm({
      message: this.translate.instant('SURE_TO_PROCEED'),
      header: this.translate.instant('CONFIRMATION'),
      accept: () => {
        this.ConFirmVendor();
      }
    });
  }


  ConFirmVendor() {
    this.spinner.show()
    this.vendorsData = this.vendorModel.validateVendor(this.vendorsData);
    this.vendorsData = this.vendorModel.validateRank(this.ranks, this.vendorsData);


    let  _vendorData=this.vendorsData as BulkDrayageVendorResponseFormData[];
    let invalidData = _vendorData .filter((v:any) => !v.validVendor  || !v.validBidAmount  || !v.validComments || !v.validRank || !v.validCurrency);
   
    if(invalidData.length > 0) {
      this.spinner.hide()
      this.toast.warning(this.translate.instant("CHECK_VENDOR_DETAILS"),this.translate.instant("WARNING"));
      return false;
    }
	else{
    this.validReason = this.reasonForSelection != null && this.reasonForSelection != undefined && this.reasonForSelection != "";
    if(!this.validReason) {
      this.spinner.hide()
      this.toast.warning(this.translate.instant("VAL_REASON_FOR_SELCTION"),this.translate.instant("WARNING"))
     return false;
    }

    let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
      apisubmitdata.vendorsData=this.vendorsData as VendorResponseFormData[];
      apisubmitdata.requestId=this.requestId;
      apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
      apisubmitdata.selectedReason=this.reasonForSelection;
      apisubmitdata.isconfirmed=true;
      apisubmitdata.requestStatus=this.requestData.requestStatusId;
      apisubmitdata.requestorMail=this.requestData.requesterMailId;
      apisubmitdata.transportType=this.transportType;
      apisubmitdata.modifiedBy=this.userAccessPermission.userId;

    this.vendorModel.submitvendorResponse(apisubmitdata,this.rateReqCalData,this.rateReqCalFileName,this.rateReqCalFileExtn)
      .subscribe({
        next:confirmVendorResponse => {
        this.vendorsData = [];
        this.ranks=[]
        this.retrieveRequestDetails(this.transportType,this.requestId,this.requestModel);
        this.refresh.emit('submit');
        this.spinner.hide();
        if(confirmVendorResponse=='SUCCESS')
        this.toast.success(this.translate.instant("VENDORS_AWARD_DONE"),this.translate.instant("SUCCESS"));
        else{
          this.toast.warning(this.translate.instant(confirmVendorResponse),this.translate.instant("WARNING"))
        }
      },
        error:error => {
          this.spinner.hide()
          this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"));
        }
        });
        return true
      }
      
}




 
}
