import { VendorResponseData } from "./vendor-response.data";

export class LiquidBulkVendorResponseData extends VendorResponseData
{
    bidAmount2: number=0;
    isSelected2:boolean=false;
    rankAssigned2:any=0;
    currencyType2:number=0;

    cleaningCost1: number=0;
    cleaningCost2: number=0;
    cleaningCost1currencyType:number=0;
    cleaningCost2currencyType:number=0;  
}