import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { LiquidRequestDetails } from "../../../data/formdata/liquid-request-details.data";
import { LiquidTruckRequestFormModel } from "../../../liquid-request-form.model";
import { RequestFormModel } from "../../../request-form.model";
import { RequestDetailsComponent } from "../.././request-details-form.component";

@Component({
    selector: 'app-liquid-request-emea-details',
    templateUrl: '..\\..\\..\\shared-templates\\request-details-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-details-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: LiquidTruckRequestFormModel }
    ]
})
export class LiquidRequestDetailsEMEAComponent extends RequestDetailsComponent implements OnInit {

  override requestData:any;
    
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        console.log("constructor")
      }

    ngOnInit(): void {
        this.requestData = new LiquidRequestDetails();
        this.transportType=1
        this.validateloggedInUser();
        this.readQueryString();
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }
    downLoadSpecialReqAttachment(){console.log('')};
    downLoadFISPQFile(){console.log('')}
    bindfiledVisiblity()
    {
      this.fieldVisiblity.requestType=true;
      this.fieldVisiblity.startDate=true;
      this.fieldVisiblity.requestor=true;
      this.fieldVisiblity.bussinessunit=true;
      this.fieldVisiblity.articleNumber=true;

      this.fieldVisiblity.product=true;
      this.fieldVisiblity.mfg=true;
      this.fieldVisiblity.freightClass=false;
      this.fieldVisiblity.cleaninCost=false;
      this.fieldVisiblity.interModel=true;
      this.fieldVisiblity.equipmentType=true;
      // Service Provider
      this.fieldVisiblity.volumeType=true;
      this.fieldVisiblity.campaignSeasonalVolume=true;
      this.fieldVisiblity.csVolumeTiming=true;
      this.fieldVisiblity.monthlyVolume=false;
      this.fieldVisiblity.centerRearUnload=true;
      this.fieldVisiblity.dropTrail=true;
      this.fieldVisiblity.days=false;
      this.fieldVisiblity.tempRange=true;
      this.fieldVisiblity.tempRequirement=true;
      this.fieldVisiblity.priorProductRestriction=true;
      this.fieldVisiblity.hazardous=true;
      this.fieldVisiblity.kosher=true;
      this.fieldVisiblity.materialTopLoad=true;
      this.fieldVisiblity.foodGradeWash=true;
      this.fieldVisiblity.loadingHrs=true;
      this.fieldVisiblity.unloadingHrs=true;
      this.fieldVisiblity.nitrogenBlanket=true;
      this.fieldVisiblity.weekendDelivery=true;
      this.fieldVisiblity.groundVapourRecovery=true;
      this.fieldVisiblity.centerRear=false;
      this.fieldVisiblity.pump = true;
      this.fieldVisiblity.chassis = true;
      this.fieldVisiblity.couplings = true;
      this.fieldVisiblity.loadingPoint =true;
      this.fieldVisiblity.gmp = true;
      this.fieldVisiblity.fami = true;
      this.fieldVisiblity.isopa = true;
      this.fieldVisiblity.halalWash = true;
      this.fieldVisiblity.unloadingHose = true;
      this.fieldVisiblity.onboardCompressor = true;
      this.fieldVisiblity.others = true;
  
  
      //Shipping->Orgin
      this.fieldVisiblity.originPlant = true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=true;
      this.fieldVisiblity.orginState=true;
      this.fieldVisiblity.orginCountry=true;
      this.fieldVisiblity.orginZipCode=true;
      //shipping-> destination
      this.fieldVisiblity.destinationPlant = true;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=true;
      this.fieldVisiblity.destinationState=true;
      this.fieldVisiblity.destinationCountry=true;
      this.fieldVisiblity.destinationZipCode=true;
  
      //Additional Info
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;
      this.fieldVisiblity.comments=true;
  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      if(this.isVendorUser){
        this.fieldVisiblity.requestor=false;
        this.fieldVisiblity.customerPreferedCarrier=false;
        this.fieldVisiblity.costVsService=false;
      }
      
    }
    
    retrieveHeaderValues() {
        this.title = "Liquid Truck";
      }
    
    bindConditionalFields(){
      console.log("")
    }
      
}