<div *ngIf="region=='NA'">
  <div *ngIf="transportType==1">
    <app-liq-vendor-na [requestId]="requestId" [transportType]="transportType"
      [statusId]="statusId"
      (refresh)="reload($event)">
    </app-liq-vendor-na>
  </div>
  <div *ngIf="transportType==2">
    <app-dry-vendor-na [requestId]="requestId" [transportType]="transportType"
      [statusId]="statusId"
     (refresh)="reload($event)">
    </app-dry-vendor-na>
  </div>
  <div *ngIf="transportType==3">
    <app-truck-load-vendor-na [requestId]="requestId" [transportType]="transportType"
      [statusId]="statusId" [requestDestination]="requestDestination"
     (refresh)="reload($event)">
    </app-truck-load-vendor-na>
  </div>
  <div *ngIf="transportType==4">
    <app-rail-rate-vendor-na [requestId]="requestId" [transportType]="transportType"
    [statusId]="statusId" 
     (refresh)="reload($event)"></app-rail-rate-vendor-na>
  </div>
  <div *ngIf="transportType==5">
    <app-bulk-drayage-vendor-na [requestId]="requestId" [transportType]="transportType"
      [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-bulk-drayage-vendor-na>
  </div>
  <div *ngIf="transportType==6">
    <app-package-drayage-vendor-na [requestId]="requestId" [transportType]="transportType"
      [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-package-drayage-vendor-na>
  </div>    
</div>

<div *ngIf="region=='SA'">

  <div *ngIf="transportType==1">
    <app-liq-vendor-sa [requestId]="requestId" [transportType]="transportType"
      [statusId]="statusId"
     (refresh)="reload($event)">
    </app-liq-vendor-sa>
  </div>
  <div *ngIf="transportType==2">
    <app-dry-vendor-sa [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId"
     (refresh)="reload($event)">
    </app-dry-vendor-sa>
  </div>
  <div *ngIf="transportType==3">
    <app-truck-load-vendor-sa [requestId]="requestId" [transportType]="transportType"
      [statusId]="statusId" [requestDestination]="requestDestination"
     (refresh)="reload($event)">
    </app-truck-load-vendor-sa>
  </div>
  <div *ngIf="transportType==4">
    <app-rail-rate-vendor-sa [requestId]="requestId" [transportType]="transportType"
    [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-rail-rate-vendor-sa>
  </div>
  <div *ngIf="transportType==5">
    <app-bulk-drayage-vendor-sa [requestId]="requestId" [transportType]="transportType"
    [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-bulk-drayage-vendor-sa>
  </div>
  <div *ngIf="transportType==6">
    <app-package-drayage-vendor-sa [requestId]="requestId" [transportType]="transportType"
    [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-package-drayage-vendor-sa>
  </div>     
</div>

<div *ngIf="region=='EMEA'">

  <div *ngIf="transportType==1">
    <app-liq-vendor-emea [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId"
     (refresh)="reload($event)">
    </app-liq-vendor-emea>
  </div>
  <div *ngIf="transportType==2">
    <app-dry-vendor-emea [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId"
     (refresh)="reload($event)">
    </app-dry-vendor-emea>
  </div>
  <div *ngIf="transportType==3">
    <app-truck-load-vendor-emea [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId" [requestDestination]="requestDestination"
     (refresh)="reload($event)">
    </app-truck-load-vendor-emea>
  </div>
  <div *ngIf="transportType==4">
    <app-rail-rate-vendor-emea [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-rail-rate-vendor-emea>
  </div>
  <div *ngIf="transportType==5">
    <app-bulk-drayage-vendor-emea [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-bulk-drayage-vendor-emea>
  </div>
  <div *ngIf="transportType==6">
    <app-package-drayage-vendor-emea [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-package-drayage-vendor-emea>
  </div>

</div>


<div *ngIf="region=='AP'">

  <div *ngIf="transportType==1">
    <app-liq-vendor-ap [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId"
     (refresh)="reload($event)"></app-liq-vendor-ap>
  </div>
  <div *ngIf="transportType==2">
    <app-dry-vendor-ap [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId"
     (refresh)="reload($event)">
    </app-dry-vendor-ap>
  </div>
  <div *ngIf="transportType==3">
    <app-truck-load-vendor-ap [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId" [requestDestination]="requestDestination"
     (refresh)="reload($event)">
    </app-truck-load-vendor-ap>
  </div>
  <div *ngIf="transportType==4">
    <app-rail-rate-vendor-ap [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-rail-rate-vendor-ap>
  </div>
  <div *ngIf="transportType==5">
    <app-bulk-drayage-vendor-ap [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-bulk-drayage-vendor-ap>
  </div>
  <div *ngIf="transportType==6">
    <app-package-drayage-vendor-ap  [requestId]="requestId" [transportType]="transportType"
     [statusId]="statusId" 
     (refresh)="reload($event)">
    </app-package-drayage-vendor-ap>
  </div>   

</div>
