
export class ProductData
{
mfg!:string;
mfgCode!:string;
friegeClass!:string;
product!:string;
articleNumber!:string;
cleaningCategoryCode!:string;
newRate!:string;
}