import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";


@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel]
  })

export class AdminComponent implements OnInit 
{
    activeTab:number=0;
    userAccessPermission!: LoggecInUserInfo;
    constructor(private userAccessModel: LoggedInUserInfoModel,private router: Router,
    private translate:TranslateService)
    {
    
    }
    ngOnInit(): void {
        this.validateloggedInUser();
    }
    protected validateloggedInUser() {
        this.userAccessPermission =this.userAccessModel.retrieveLoggedInUserSitePermission();
       
        let url1 = "";
        url1 = window.location.href;
        sessionStorage.setItem('requestPath', url1);
       
        if (this.userAccessPermission.username === undefined || this.userAccessPermission.username === null && this.userAccessPermission.username === "" || this.userAccessPermission.username === " ") {
            this.router.navigate(['/login']);
        }
        
        this.ValidateUseraccessPermission();
 
    }
    
    // Validate the accesspermission
    private ValidateUseraccessPermission() {
        if (!this.userAccessPermission.site_access.appAccess && !this.userAccessPermission.site_access.adminAccess) {
            if (this.userAccessPermission.username == null || this.userAccessPermission.username == undefined
                || this.userAccessPermission.username == "" || this.userAccessPermission.username == "null"
                || this.userAccessPermission.username == "undefined"
                || this.userAccessPermission.business_unit == null || this.userAccessPermission.business_unit == undefined
                || this.userAccessPermission.business_unit == "" || this.userAccessPermission.business_unit == "null"
                || this.userAccessPermission.business_unit == "undefined") {

                this.router.navigate(['/login']);
            } else {
                this.router.navigate(['/home']);
            }
        }
        
    }

    onTabChange(e:any) {
        this.activeTab=e.index;
    }

}