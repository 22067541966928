import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { LiquidTruckRequestFormData } from "../../../data/formdata/liquid-request-form.data";
import { PlantCodeMasterData, RequestMasterData } from "../../../data/masterdata/request-master.data";
import { LiquidTruckRequestMasterModel } from "../../../liquid-request-master.model";
import { RequestMasterModel } from "../../../request-master.model";
import { CommonRequestComponent } from "../../request-form-common.component";
import { ProductModel } from "src/app/shared/product/products.model";
import { RequestFormModel } from "../../../request-form.model";
import { LiquidTruckRequestFormModel } from "../../../liquid-request-form.model";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";


@Component(
  {
    selector: 'app-liquid-request-ap-form',
    templateUrl: '..\\..\\..\\shared-templates\\request-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel, ProductModel,
      { provide: RequestFormModel, useClass: LiquidTruckRequestFormModel },
      { provide: RequestMasterModel, useClass: LiquidTruckRequestMasterModel }

    ]
  }
)

export class LiquidRequestFormAPComponent extends CommonRequestComponent implements OnInit,OnDestroy {

  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;


  // override requestMaster: RequestMasterData = new RequestMasterData;
  //override requestData: LiquidTruckRequestFormData = new LiquidTruckRequestFormData;
  
  override requestData:any;
  override requestMaster:any;
  _toast: ToastrService
  _translate:TranslateService
  pageLanguage:string="";
  constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
    private productModel: ProductModel, requestModel: RequestFormModel,
    toast: ToastrService, router: Router, spinner: NgxSpinnerService,
    private spinners: NgxSpinnerService, route: ActivatedRoute,translate:TranslateService,private _appServ: AppServiceService) {
    super(userAccessModel, masterModel, requestModel, router, toast, spinner, route,translate);
    this._toast = toast;
    this._translate = translate;
    this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
    this.messageReceived = message;    
    let newLang=sessionStorage.getItem('selectedLanguage');

    if(this.pageLanguage!=newLang && this.pageLanguage!=""){
      this.ngOnInit();
    }
    });
  }

  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }




  ngOnInit(): void {
    this.pageLanguage=sessionStorage['selectedLanguage'];
    this.transportType = 1;
    this.startDateLable = "START_DATE";

    this.requestMaster = new RequestMasterData();
    this.plantCodeShippingDetails=new PlantCodeMasterData();
    this.requestData = new LiquidTruckRequestFormData();
    this.requestData.transportType = 1;
    this.bindfiledVisiblity();
    this.retrieveHeaderValues();
    //login
    this.validateloggedInUser();
    //Request Master
    this.retrieveMasterData(this.transportType);
    //plantcode with shipping data
    this.retrievePlantCodeMasterData(this.transportType);
    //Article
    this.retrieveArticleList(this.spinners)
    //retrieve Query Parameters
    this.retrieveQueryPramaters();
    this.retriveLang(this.pageLanguage);

  }
  bindfiledVisiblity() {
    this.fieldVisiblity.requestType = true;
    this.fieldVisiblity.startDate = true;
    this.fieldVisiblity.requestor = true;
    this.fieldVisiblity.bussinessunit = true;
    this.fieldVisiblity.articleNumber = true;
    this.fieldVisiblity.IsProductReadOnly=false;
    this.fieldVisiblity.product = true;
    this.fieldVisiblity.IsProductReadOnly = false;
    this.fieldVisiblity.mfg = false;
    this.fieldVisiblity.freightClass = false;
    this.fieldVisiblity.cleaninCost = false;
    this.fieldVisiblity.interModel = true;
    this.fieldVisiblity.equipmentType = true;

    // Service Provider
    this.fieldVisiblity.volumeType =true;
    this.fieldVisiblity.annualVolume =true;
    this.fieldVisiblity.monthlyVolume = false;
    this.fieldVisiblity.centerRearUnload = false;
    this.fieldVisiblity.dropTrail = false;
    this.fieldVisiblity.days = false;
    this.fieldVisiblity.tempRange = true;
    this.fieldVisiblity.tempRequirement = true;
    this.fieldVisiblity.priorProductRestriction = true;
    this.fieldVisiblity.hazardous = false;
    this.fieldVisiblity.hazardousMandatory = true;
    this.fieldVisiblity.kosher = false;
    this.fieldVisiblity.materialTopLoad = false;
    this.fieldVisiblity.foodGradeWash = false;
    this.fieldVisiblity.loadingHrs = true;
    this.fieldVisiblity.unloadingHrs = true;
    this.fieldVisiblity.nitrogenBlanket = true;
    this.fieldVisiblity.weekendDelivery = false;
    this.fieldVisiblity.groundVapourRecovery = false;
    this.fieldVisiblity.centerRear = false;
    this.fieldVisiblity.isoTankRentalRequirement = true;
    this.fieldVisiblity.isoTankRentalPeriod = true;
    this.fieldVisiblity.tankType = true;
    this.fieldVisiblity.specificRemarkForTankType =true;
    this.fieldVisiblity.topBottomLoad = true;
    this.fieldVisiblity.topBottomUnload = true;
    this.fieldVisiblity.cleaningService = true;
    this.fieldVisiblity.cleaningFrequency =true;
    this.fieldVisiblity.heatingService =true;
    this.fieldVisiblity.heatingTemperature = true;
    this.fieldVisiblity.priorProductRestrictionDetails = true;
    this.fieldVisiblity.tariffZone = true;
    this.fieldVisiblity.dangerousGoodsClass = true;
    this.fieldVisiblity.otherAdditionalServiceRequirement = true;


    //Shipping->Orgin
    this.fieldVisiblity.originPlant = true;
    this.fieldVisiblity.shippingName = true;
    this.fieldVisiblity.orginCity = true;
    this.fieldVisiblity.orginState = true;
    this.fieldVisiblity.orginCountry = true;
    this.fieldVisiblity.orginZipCode = true;
    //shipping-> destination
    this.fieldVisiblity.destinationPlant = true;
    this.fieldVisiblity.consigneeName = true;
    this.fieldVisiblity.destinationCity = true;
    this.fieldVisiblity.destinationState = true;
    this.fieldVisiblity.destinationCountry = true;
    this.fieldVisiblity.destinationZipCode = true;

    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier = false;
    this.fieldVisiblity.costVsService = false;
    this.fieldVisiblity.comments = true;

    this.fieldVisiblity.materialDoc = true;
    this.fieldVisiblity.materialLink = true;
    this.fieldVisiblity.sdsLink = true;
    this.fieldVisiblity.sdsAttachment = true;
    this.fieldVisiblity.additionalAttachment = true;
    this.fieldVisiblity.additionalAttachment2 = true;

  }
  onAnnualVolumeChange(event: any){
    console.log("");
   

  }
  override onArticleClear(event:any){
    this.resetArticleData();
  }

  onArticleComplete(event:any){
    super.retrieveProductDetails(event)
  }
  onArticleSelected(event:any){
    this.processArticleNumber(event);

  }

  retrieveHeaderValues() {
    this.title = "Liquid Truck";
    this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
  }
  resetArticleData() {
    this.requestData.mfg = "";
    this.selectedMFGCode = "";
    this.requestData.freighClass = "";
    this.requestData.cleaningCost = "";
    this.selectedArticle = [];
    this.requestData.product = "";
    this.requestData.articleNumber = "";

  }
  GenerarateArticleNumberEvent() {
    this.selectedArticle.label = this.requestData.articleNumber;
    return {
      label: this.requestData.articleNumber
    };
  }
  handlehazardousChange(event:any){
    console.log("");

  }
  processArticleNumber(event: any) {
    this.requestData.articleNumber = event.label;
    this.productModel.retrieveProductForArticle(event.label).subscribe({
      next:result => {
      this.requestData.articleNumber = result.articleNumber;
      this.requestData.cleaningCost = result.newRate;
      this.requestData.freighClass = result.friegeClass;
      this.requestData.mfg = result.mfg;
      this.requestData.product = result.product;
      this.selectedMFGCode = result.mfgCode;
    }, error:error => {
      this._toast.error(this._translate.instant(error),this._translate.instant("ERROR"))
    }
    });

  }
  public clearForm() {
    this.OnCancel();
  }
  override OnCancel() {

    this.clearData(1);
    this.requestData.articleNumber = "";
    this.requestData.cleaningCost = "";
    this.requestData.freighClass = "";
    this.requestData.internModel = "";
    this.requestData.mfg = "";
    this.requestData.equipmentTypeId=0;

    //Service provider
    this.requestData.days = 0;
    this.requestData.dropTrail = "";
    this.requestData.monthlyVolume = 0;
    this.requestData.monthlyVolumeUnitId = 0;
    this.requestData.centerRear = "";
    this.requestData.centerRearUnload = "";
    this.requestData.foodGradeWash = "";
    this.requestData.groundVapourDelivery = "";
    this.requestData.hazardous = "";
    this.requestData.kosher = "";
    this.requestData.loadingHours = ''
    this.requestData.materialTopLoad = "";
    this.requestData.nitrogenBlanket = "";
    this.requestData.priorProductRestriction = "";
    this.requestData.tempRange = "";
    this.requestData.tempRequirementId = 0;
    this.requestData.unloadingHour = '';
    this.requestData.weekendDelivery = "";
    this.requestData.isoTankRentalRequirementId=0;
    this.requestData.isoTankRentalPeriod="";
    this.requestData.tankTypeId=0;
    this.requestData.specificRemarkForTankType="";
    this.requestData.topBottomLoad="";
    this.requestData.topBottomUnload="";
    this.requestData.cleaningService="";
    this.requestData.cleaningFrequency="";
    this.requestData.heatingService="";
    this.requestData.heatingTemperature="";
    this.requestData.priorProductRestrictionDetails="";
    this.requestData.tariffZone="";
    this.requestData.dangerousGoodsClassId=0;
    this.requestData.otherAdditionalServiceRequirement="";

    this.resetArticleData();
  }

  onSubmitRequest() {
    this.handlingDefaultDropdownValues();
    this.requestData.equipmentTypeId=this.requestData.equipmentTypeId??0;
    this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;
    this.requestData.averageProductId=this.requestData.averageProductId??0;
    this.requestData.isoTankRentalRequirementId=this.requestData.isoTankRentalRequirementId??0;
    this.requestData.tankTypeId=this.requestData.tankTypeId??0;
    this.requestData.dangerousGoodsClassId=this.requestData.dangerousGoodsClassId??0;
    if (this.requestData.monthlyVolume == 0 || this.requestData.monthlyVolume == undefined || this.requestData.monthlyVolume == null) {
      this.requestData.monthlyVolume = 0;
    }
    if (this.requestData.annualVolume == 0 || this.requestData.annualVolume == undefined || this.requestData.annualVolume == null) {
      this.requestData.annualVolume = 0;
    }

    if (this.requestData.days == 0 || this.requestData.days == undefined || this.requestData.days == null) {
      this.requestData.days = 0;
    }
    this.validateMasterData();
    this.createRequest();
  }

  validateMasterData(){
    if(this.requestData.tempRequirementId!=0
      && !this.requestMaster.tempRequirementMaster.find((t:any)=>t.value==this.requestData.tempRequirementId))
    {
     this.requestData.tempRequirementId=0;
    }
  }

  requestTypeChangeEvent(event:any){
    console.log("");
  }

  bindConditionalFields(){
    console.log("");

  }
  onPlantSelectPopulate(event:any,index:number){
    console.log("");

  }
  onSpecialRequirement(event:any){  }
}