import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { DataFieldVisiblity } from "../data/common/datafieldvisiblity.data";
import { RequestFormData } from "../data/formdata/request-common.data";
import { PlantCodeMasterData, RequestMasterData } from "../data/masterdata/request-master.data";
import { RequestFormModel } from "../request-form.model";
import { RequestMasterModel } from "../request-master.model";
import { TranslateService } from "@ngx-translate/core";
import { LiquidTruckRequestFormData } from "../data/formdata/liquid-request-form.data";
import { DryTruckRequestFormData } from "../data/formdata/dry-request-form.data";

import { isNullOrUndefined } from 'src/app/shared/utils/nullorundefined';

export abstract class CommonRequestComponent {



  userAccessPermission!: LoggecInUserInfo;
  title: string = "";
  transportType!: number;
  description: string = "";
  validSubmit: boolean = true;
  editMode: boolean = false;
  artMode: boolean = false;
  selectedArticle: any;

  originPlantMode:boolean = false;
  destinationPlantMode:boolean = false;
  selectedOriginPlantCode:any;
  selectedDestinationPlantCode:any;
  is_FISPQ_fileUpload:boolean = false;
  is_SpecialReq_fileUpload:boolean = false;

  selectedMFGCode!: string;
  requestMaster: RequestMasterData = new RequestMasterData;
  //requestData: RequestData = new RequestData;
   requestData: RequestFormData = new RequestFormData;
  dupArticleOpts: any;
  is_sds_fileUpload: boolean = false;
  is_Additional_fileUpload1: boolean = false;
  is_Additional_fileUpload2: boolean = false;
  queryparams: any;
  selectedrequestId!: number;
  minDateValue = new Date();
  minEndDate=new Date();
  lang:any;
  
 English={
  firstDayOfWeek: 0,
   dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
 }

 Spanish={
  firstDayOfWeek: 0,
   dayNamesMin: [ "D","L", "M","X","J","V","S"],
   
    monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre", "Octubre","Noviembre","Diciembre"]
    
 }
Portuguese={
  firstDayOfWeek: 0,
   dayNamesMin: ["Do","Se","Te","Qa","Qi","Sx","Sa"],
    monthNames: [ "Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro", "Novembro","Dezembro"]
 }
Chinese={
  firstDayOfWeek: 0,
   dayNamesMin: ["日","一","二","三","四","五","六"],
    monthNames: ["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"],
 }
Japanese={
  firstDayOfWeek: 0,
   dayNamesMin: ["日","月","火","水","木","金","土"],
    monthNames: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],
 }
Korean={
  firstDayOfWeek: 0,
   dayNamesMin: ["일","월","화","수","목","금","토"],
    monthNames: ["1월","2월","3월","4월","5월","6월","7월","8월","9월","10월","11월","12월"],
 }

  startDateLable: string = "START_DATE";
  prefferedCarriear: string = "BU/Customer Preferred Carrier";

  plantCodeShippingDetails: PlantCodeMasterData = new PlantCodeMasterData; 

  fieldVisiblity: DataFieldVisiblity = new DataFieldVisiblity();


  constructor(protected userAccessModel: LoggedInUserInfoModel,
    private masterModel: RequestMasterModel,
    private requestModel: RequestFormModel,
    private router: Router, private toast: ToastrService,
    private spinner: NgxSpinnerService, private route: ActivatedRoute,private translate:TranslateService) { }

  public abstract clearForm():any;
  // public abstract  onCountryCheck(event:any)
  public abstract bindConditionalFields():any;
  public abstract onPlantSelectPopulate(event:any,index:number):any;
  retrieveQueryPramaters() {
    this.route.queryParams.subscribe(params => {
      this.queryparams = params;
      this.processQueryParams();

    });
  }
  retriveLang(lang:string){
    switch(lang) { 
      case 'English': { 
        this.lang=this.English
         break; 
      } 
      case 'Spanish': { 
        this.lang=this.Spanish
         break; 
      } 
      case 'Portuguese': { 
        this.lang=this.Portuguese
         break; 
      } 
      case 'Japanese': { 
        this.lang=this.Japanese
         break; 
      } 
      case 'Chinese': { 
        this.lang=this.Chinese
         break; 
      } 
      case 'Korean': { 
        this.lang=this.Korean
         break; 
      } 
      default: { 
        this.lang=this.English
         break; 
      } 
    }
  }
  
  ViewArticleInput() {
    this.artMode = false;
  }

  ViewPlantComplete(isOrigin:boolean,isMexico:boolean,index:number){
    if(isOrigin){
      this.originPlantMode=false;
    }
    else if(isMexico){
      (this.requestData as LiquidTruckRequestFormData).mexicoDestination[index].destinationPlantMode=false;
    }
    else{
      this.destinationPlantMode=false;
    }
  }
 
  payerOptionChange(event:any){
    console.log('');
  }
  
  onShipmentTypeChange(event:any){
    console.log('');
  }
  onPriorProductRestrionChange(event:any){
    console.log('');
  }
  handleSeasonalChange(event:any){
    console.log('');
  }
  onmandadoryDocsInsideTruck(event:any){
    console.log('')
  }
  onmandDocsChangeEvent(event:any){
    console.log('')
  }

  onCountryCheck(event:any){
    console.log('')
  }
  OnRemoveFISPQAttachment(){
    console.log('')
  }
  onMexicoDestinationRemove(i:number){
    console.log('')
  }
  OnFISPQFileUpload(event:any,pFISPQFileUpload:any){
    console.log('')
  }
  
  resetMexicoDestinationPlantCodeData(i:number){
    console.log('')
  }
  
  onMexicoDestinationAdd(){
    console.log('')
  }
  OnSpecialReqFileUpload(event:any){
    console.log('')
  }
  OnRemoveSpecialReqAttachment(){
    console.log('')
  }
  

  
    clearData(transportType: number) {

    this.is_sds_fileUpload = false;
    this.is_Additional_fileUpload1 = false;
    this.is_Additional_fileUpload2 = false;

    //General
    this.requestData.requestTypeId =0;
    this.requestData.product = "";
    this.requestData.startDate = "";
    this.requestData.transportType = transportType;
    this.requestData.annualVolume = 0;
    this.requestData.annualVolumeUnitId = 0;
    this.requestData.annualVolumeStartDate = "";
    this.transportType = transportType;

    this.requestData.volumeType="Annual";
    this.requestData.campaignSeasonalVolume=0;
    this.requestData.campaignSeasonalVolumeUid=0;
    this.requestData.csStartDate="";
    this.requestData.csEndDate="";

    //ShippingDetails
    this.requestData.destinationPlantId=0;
    this.requestData.destinationCity = "";
    this.requestData.destinationCountry = "";
    this.requestData.destinationName = "";
    this.requestData.destinationState = "";
    this.requestData.destinationZipCode = "";

    this.requestData.originPlantId=0;
    this.requestData.orginCity = "";
    this.requestData.orginCountry = "";
    this.requestData.orginName = "";
    this.requestData.orginState = "";
    this.requestData.orginZipCode = "";


    //Additional info
    this.requestData.additionalAttchment = "";
    this.requestData.additionalAttchment2 = "";
    this.requestData.additionalComments = "";
    this.requestData.additionalFileExtn = "";
    this.requestData.additionalFileExtn2 = "";
    this.requestData.additionalFileName = "";
    this.requestData.additionalFileName2 = "";
    this.requestData.costVsService = "";
    this.requestData.isCost = false;
    this.requestData.isService = false;
    this.requestData.prefferedVendor = "";
    this.requestData.priority = "";
    this.requestData.sdsAttachment = "";
    this.requestData.sdsAttchmentFileExtn = "";
    this.requestData.sdsFileName = "";
    this.selectedOriginPlantCode = "";
    this.selectedDestinationPlantCode = "";
    this.originPlantMode = false;
    this.destinationPlantMode = false;
    this.artMode=false;
  }
  //Process the Query String 

  private processQueryParams() {
    if (this.queryparams.reqref != undefined && this.queryparams.reqref != 0) {
      this.selectedrequestId = Number(atob(this.queryparams.reqref))
      this.retrieveRequestData();
    }
    else{
      this.bindConditionalFields()
    }
  }
  retrieveRequestData() {
    this.spinner.show()
    if (this.selectedrequestId != undefined && this.selectedrequestId != 0) {
      this.requestModel.retrieveRequestDetails(this.transportType, this.selectedrequestId).then(response => {

        if (response != undefined && response.requestId != undefined && response.requestId != 0) {
          this.editMode = true;
          this.artMode = true;
          this.originPlantMode = true;
          this.destinationPlantMode = true;
          this.requestData = response;
          if(this.requestData.volumeType=='Campaign'){
            this.fieldVisiblity.campaignSeasonalVolume=true;
            this.fieldVisiblity.csVolumeTiming=true;
            this.fieldVisiblity.annualVolume=false;
          }
          this.selectedArticle = []
          this.processOriginDestinationPlantCodes()
          let event = this.GenerarateArticleNumberEvent()
          this.processArticleNumber(event);
          this.processDateFields();          
          this.ProcessFileAttached();
          this.requestData.costVsService = this.generateCostVsServiceValues(this.requestData.priority, this.requestData.isCost, this.requestData.isService);
          this.bindConditionalFields();
        }
        else {
          this.toast.error(this.translate.instant("INVALID_REF_NUMBER"));
        }
        this.spinner.hide()

      })
    }
    else {
      this.OnCancel();
    }

  }
  processOriginDestinationPlantCodes(){
    if(this.requestData.originPlantId!=0){
      this.processPlantCode(this.requestData.originPlantId,true)
    }
    if(this.requestData.destinationPlantId!=0){
      this.processPlantCode(this.requestData.destinationPlantId,false)
    }
  }
  processDateFields(){
    let now = new Date(this.requestData.startDate);
    this.minEndDate=new Date(this.requestData.csStartDate);
    this.requestData.startDate = moment(now).format('YYYY-MM-DD');
    if(this.requestData.csStartDate!=null){
      this.requestData.csStartDate=moment(new Date(this.requestData.csStartDate)).format('YYYY-MM-DD');
    }
    if(this.requestData.csEndDate!=null){
      this.requestData.csEndDate = moment(new Date(this.requestData.csEndDate)).format('YYYY-MM-DD');
    }
    if(this.requestData.annualVolumeStartDate!=null){
      this.requestData.annualVolumeStartDate = moment(new Date(this.requestData.annualVolumeStartDate)).format('YYYY-MM-DD');
    }
  }
  
  onArticleClear(event:any){}
  oncsStartDateSelect(){
    this.minEndDate=new Date(this.requestData.csStartDate);
  }

  setStartDate(event:any)
  {
    this.requestData.annualVolumeStartDate = this.requestData.startDate;
    this.requestData.csStartDate = this.requestData.startDate;
    this.minEndDate=new Date(this.requestData.csStartDate);
  }

  onCsVolumeRequiredChange(){
    if(this.requestData.volumeType=='Campaign'){
      this.fieldVisiblity.campaignSeasonalVolume=true;
      this.fieldVisiblity.csVolumeTiming=true;
      this.fieldVisiblity.annualVolume =false;
      this.requestData.annualVolume=0;
      this.requestData.annualVolumeUnitId=0;
      this.requestData.csStartDate=this.requestData.startDate;
    }
    else{
      this.fieldVisiblity.annualVolume = true;
      this.fieldVisiblity.campaignSeasonalVolume=false;
      this.fieldVisiblity.csVolumeTiming=false;
      this.requestData.campaignSeasonalVolume=0;
      this.requestData.campaignSeasonalVolumeUid=0;
      this.requestData.annualVolumeStartDate=this.requestData.startDate;
      this.requestData.csEndDate="";
    }
    this.minEndDate=new Date(this.requestData.csStartDate);
  }

  processPlantCode(plantCodeId:number,isOrigin:boolean){
    let plantCodeObject:any;
    this.masterModel.retrievePlantCodebyMasterId(this.transportType,plantCodeId).subscribe({
      next:response=>{
      plantCodeObject= {label:response.masterValue} 
      if(isOrigin){
        this.selectedOriginPlantCode=plantCodeObject 
      }
      else{
        this.selectedDestinationPlantCode=plantCodeObject;
      }
    },
    error:error => {
      this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"))
    }
  })
  }

  

  private ProcessFileAttached() {
    if (this.requestData.sdsFileName != undefined && this.requestData.sdsFileName != "") {

      this.is_sds_fileUpload = true;
    }

    if (this.requestData.additionalFileName != undefined && this.requestData.additionalFileName != "") {

      this.is_Additional_fileUpload1 = true;
    }
    if (this.requestData.additionalFileName2 != undefined && this.requestData.additionalFileName2 != "") {

      this.is_Additional_fileUpload2 = true;
    }    
  }

  abstract GenerarateArticleNumberEvent():any;

  generateCostVsServiceValues(Priority:any, cost:any, service:any) {
    if (cost) {
      switch (Priority) {
        case 'Low': return 'c1';
        case 'Medium': return 'c2';
        case 'High': return 'c3';
        case 'NA': return '0';
      }
    }
    if (service) {
      switch (Priority) {
        case 'Low': return 's1';
        case 'Medium': return 's2';
        case 'High': return 's3';
        case 'NA': return '0';
      }
    }
    if (!service && !cost) {
      if (Priority == 'NA') {
        return '0';
      }
      else{
        return '0'
      }
    }
    else{return '0'}
  }

  OnCancel() {
    console.log("");
  }

  // retrieve the article list
  protected retrieveArticleList(spinners: any) {
    this.masterModel.retrieveRequestArticleMaster().subscribe(response => {
      this.dupArticleOpts = response;
      this.requestMaster.articleOpts = response
    })
  }


  numericOnly(event:any): boolean {
    let patt = /^(\d)$/;
    return patt.test(event.key);

  }

  ///Retrieve the master data for the seleted transportType
  retrieveMasterData(transportType: number) {
    this.masterModel.retrieveRequestMaster(transportType).subscribe(retrieveRequestMasterResponse => {
      this.requestMaster = retrieveRequestMasterResponse;
    });
  }

  retrievePlantCodeMasterData(transportType:number){
    console.log("")
  }

  onPlantCodeCompleted(event:any,isOrigin:boolean,isMultipleDestinations:boolean,index:number){
      this.masterModel.retrieveRequestMasterPlantCodes(this.transportType,isOrigin, event.query).subscribe({
        next:response=>{
        if(isOrigin){
          
          this.plantCodeShippingDetails.originPlantCodeMaster=response.originPlantCodeMaster;
          this.plantCodeShippingDetails.plantShippingData=response.plantShippingData;
        }
        else{
          this.plantCodeShippingDetails.destinationPlantCodeMaster=response.destinationPlantCodeMaster;
          this.plantCodeShippingDetails.plantShippingData=response.plantShippingData;

        }
      },
      error:error=>{
        this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"));
        if(isOrigin){
          this.selectedOriginPlantCode={};
          this.plantCodeShippingDetails.originPlantCodeMaster=[];
        }
        else{
          this.plantCodeShippingDetails.destinationPlantCodeMaster=[];
          if(isMultipleDestinations && this.transportType==1){
            (this.requestData as LiquidTruckRequestFormData).mexicoDestination[index].selectedDestinationPlantCode={};
          }
          else if(isMultipleDestinations && this.transportType==2){
            (this.requestData as DryTruckRequestFormData).mexicoDestination[index].selectedDestinationPlantCode={};
          }
          else{
            this.selectedDestinationPlantCode={};
          }
        }
      }
      })
  }


  onPlantSelected(event:any,isMexico:boolean,index:number){
    if(event.value){
      let addDetails=this.plantCodeShippingDetails.plantShippingData.find(plant=>plant.masterId==event.value);
      if(addDetails?.isOriginPlantCode){
      this.requestData.originPlantId=this.selectedOriginPlantCode.value;
      this.requestData.orginName=addDetails.masterName;
      this.requestData.orginCity=addDetails.city;
      this.requestData.orginState=addDetails.state;
      this.requestData.orginZipCode=addDetails.zip;
      this.requestData.orginCountry=this.requestMaster.countryMaster.find(co=>co.label==addDetails.country)?.value;
      }
      else if(isMexico){
        this.onPlantSelectPopulate(event,index);
      }
      else{
        this.requestData.destinationPlantId=this.selectedDestinationPlantCode.value;
        this.requestData.destinationName=(addDetails!.masterName);
        this.requestData.destinationCity=addDetails!.city;
        this.requestData.destinationState=addDetails!.state;
        this.requestData.destinationZipCode=addDetails!.zip;
        this.requestData.destinationCountry=this.requestMaster.countryMaster.find(co=>co.label==addDetails!.country)?.value;
      }
      
    }
  }

  resetOriginPlantCodeData(){ 

    this.requestData.originPlantId=0;
    this.requestData.orginCity = "";
    this.requestData.orginCountry = "";
    this.requestData.orginName = "";
    this.requestData.orginState = "";
    this.requestData.orginZipCode = "";
  }

  resetDestinationPlantCodeData(){
    this.requestData.destinationPlantId=0;
    this.requestData.destinationCity = "";
    this.requestData.destinationCountry = "";
    this.requestData.destinationName = "";
    this.requestData.destinationState = "";
    this.requestData.destinationZipCode = "";
  }

  retrieveProductDetails(event:any){
    let filtereArticle: any[] = [];
    let query = event.query.trim();

    this.requestMaster.articleOpts = this.dupArticleOpts;
    if (this.requestMaster.articleOpts == undefined) { this.requestMaster.articleOpts = []; }
    if (query != "" && query != null && query != undefined) {
      filtereArticle = this.filterArticle(query);

      if (filtereArticle.length == 0) {
        this.processArticleNumber(event).subscribe((result:any) => {
          this.requestData = result;
        });
        this.resetArticleData();
        this.toast.error(this.translate.instant("INVALID_ARTICLE_NUMBER"));
      }
    }
    else {
      this.processArticleNumber(event).subscribe((result:any) => {
        this.requestData = result;
      });
      this.resetArticleData();
    }

    this.requestMaster.articleOpts = filtereArticle;
  }

  abstract resetArticleData():any;
  abstract processArticleNumber(event:any):any;



  //ValidateUser
  protected validateloggedInUser() {
    this.userAccessPermission = this.userAccessModel.retrieveLoggedInUserSitePermission();

    let url1 = "";
    url1 = window.location.href;
    sessionStorage.setItem('requestPath', url1);

    if (this.userAccessPermission.username === undefined || this.userAccessPermission.username === null && this.userAccessPermission.username === "" || this.userAccessPermission.username === " ") {
      this.router.navigate(['/login-qa']);
    }

    this.ValidateUseraccessPermission();

  }


  private filterArticle(query: any): any[] {

    let filtereArticle: any[] = [];
    this.requestMaster.articleOpts.forEach(data => {
      if (data != null && data != undefined) {
        let article = data;
        if (article.label.toLowerCase().trim().startsWith(query.toLowerCase())) {
          filtereArticle.push(article);

        }
      }
    });
    return filtereArticle;
  }


  // Validate the accesspermission
  private ValidateUseraccessPermission() {
    if (!this.userAccessPermission.site_access.appAccess && !this.userAccessPermission.site_access.lpAccess) {
      if (this.userAccessPermission.username == null || this.userAccessPermission.username == undefined
        || this.userAccessPermission.username == "" || this.userAccessPermission.username == "null"
        || this.userAccessPermission.username == "undefined"
        || this.userAccessPermission.business_unit == null || this.userAccessPermission.business_unit == undefined
        || this.userAccessPermission.business_unit == "" || this.userAccessPermission.business_unit == "null"
        || this.userAccessPermission.business_unit == "undefined") {

        this.router.navigate(['/login']);
      } else {
        this.router.navigate(['/home']);
      }
    }
    else {
      this.retrieveLoggedUserInformation()
    }
  }

  private retrieveLoggedUserInformation() {
    let userAccessPermission = this.userAccessModel.retrieveLoggedInUserSitePermission();
    this.requestData.requestor = userAccessPermission.firstname + " " + userAccessPermission.lastname;
    this.requestData.requesterMailId = userAccessPermission.email;
    this.requestData.requestorUserid = userAccessPermission.username;
    this.requestData.businessUnit = userAccessPermission.business_unit;
    this.requestData.modifiedBy = userAccessPermission.userId;
    this.requestData.requestedBy = userAccessPermission.userId;
    this.requestData.region= Number(sessionStorage.getItem('regionId'));

  }

  OnSDSFileUpload(event:any,upload:any) {
    this.spinner.show();
    let fileData = event.files[0];
    const element = event.files[0];
    if (element.size > 3145728) {
      this.spinner.hide();
      this.toast.warning(this.translate.instant("FILE_LIMIT_EXCEEDED"));
      upload.clear();
      return false;
    } else {
      this.requestData.sdsFileName = fileData.name;
      this.requestData.sdsAttchmentFileExtn = fileData.type;
      this.requestData.sdsAttachment = "";
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.requestData.sdsAttachment = fileReader.result;
        this.requestData.sdsAttachment = this.requestData.sdsAttachment.replace(`data:${this.requestData.sdsAttchmentFileExtn};base64,`, '');
        this.is_sds_fileUpload = true;
      }
      fileReader.readAsDataURL(fileData);
      this.spinner.hide();
      return true;
    }

  }

  OnRemoveSDSAttachment() {
    this.requestData.sdsAttachment = "";
    this.requestData.sdsFileName = "";
    this.requestData.sdsAttchmentFileExtn = "";
    this.is_sds_fileUpload = false;
  }

  OnAdditionalFileUpload1(event:any,upload:any) {
    this.spinner.show();

    let fileData = event.files[0];
    const element = event.files[0];
    if (element.size > 3145728) {
      this.spinner.hide();
      this.toast.warning(this.translate.instant("FILE_LIMIT_EXCEEDED"));
      upload.clear()
      return false;
    } else {
      this.requestData.additionalFileName = fileData.name;
      this.requestData.additionalFileExtn = fileData.type;
      this.requestData.additionalAttchment = "";
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.requestData.additionalAttchment = fileReader.result;
        this.requestData.additionalAttchment = this.requestData.additionalAttchment.replace(`data:${this.requestData.additionalFileExtn};base64,`, '');
        this.is_Additional_fileUpload1 = true;
      }
      fileReader.readAsDataURL(fileData);
      this.spinner.hide();
      return true;
    }

  }

  OnRemoveAdditionalAttachment1() {
    this.requestData.additionalAttchment = "";
    this.requestData.additionalFileName = "";
    this.requestData.additionalFileExtn = "";
    this.is_Additional_fileUpload1 = false;
  }

  OnAdditionalFileUpload2(event:any,upload:any) {
    this.spinner.show();

    let fileData = event.files[0];
    const element = event.files[0];
    if (element.size > 3145728) {
      this.spinner.hide();
      this.toast.warning(this.translate.instant("FILE_LIMIT_EXCEEDED"));
      upload.clear()
      return false;
    } else {
      this.requestData.additionalFileName2 = fileData.name;
      this.requestData.additionalFileExtn2 = fileData.type;
      this.requestData.additionalAttchment2 = "";
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.requestData.additionalAttchment2 = fileReader.result;
        this.requestData.additionalAttchment2 = this.requestData.additionalAttchment2.replace(`data:${this.requestData.additionalFileExtn2};base64,`, '');
        this.is_Additional_fileUpload2 = true;
      }
      fileReader.readAsDataURL(fileData);
      this.spinner.hide();
      return true;
    }

  }

  OnRemoveAdditionalAttachment2() {
    this.requestData.additionalAttchment2 = "";
    this.requestData.additionalFileName2 = "";
    this.requestData.additionalFileExtn2 = "";
    this.is_Additional_fileUpload2 = false;
  }


  getCostVsServiceValue(value:any): any {
    let returnValue = '';
    switch (value) {
      case 'c1':
        returnValue = "Low";
        this.requestData.isCost = true;
        break;
      case 'c2':
        returnValue = "Medium";
        this.requestData.isCost = true;
        break;
      case 'c3':
        returnValue = "High";
        this.requestData.isCost = true;
        break;
      case 's1':
        returnValue = "Low";
        this.requestData.isService = true;
        break;
      case 's2':
        returnValue = "Medium";
        this.requestData.isService = true;
        break;
      case 's3':
        returnValue = "High";
        this.requestData.isService = true;
        break;
      case '0':
        returnValue = "NA";
        this.requestData.isCost = false;
        this.requestData.isService = false;
        break;
    }
    return returnValue;
  }
  protected createRequest() {
    this.spinner.show();
    this.requestData.modifiedBy = this.userAccessPermission.userId;
    this.requestData.priority = this.getCostVsServiceValue(this.requestData.costVsService)
    this.requestData.region= parseInt(!isNullOrUndefined(sessionStorage.getItem('regionId'))?sessionStorage['regionId']:0);
    if(this.requestData.startDate!=null && this.requestData.startDate!=="" && this.requestData.startDate!=undefined) {
      this.requestData.startDate=moment(new Date(this.requestData.startDate)).format('YYYY-MM-DD')
    }
    if(this.requestData.csStartDate!=null && this.requestData.csStartDate!=="" && this.requestData.csStartDate!=undefined) {
      this.requestData.csStartDate=moment(new Date(this.requestData.csStartDate)).format('YYYY-MM-DD')
    }
    if(this.requestData.csEndDate!=null && this.requestData.csEndDate!=="" && this.requestData.csEndDate!=undefined) {
      this.requestData.csEndDate=moment(new Date(this.requestData.csEndDate)).format('YYYY-MM-DD')
    }
    if(this.requestData.annualVolumeStartDate!=null && this.requestData.annualVolumeStartDate!=="" && this.requestData.annualVolumeStartDate!=undefined) {
      this.requestData.annualVolumeStartDate=moment(new Date(this.requestData.annualVolumeStartDate)).format('YYYY-MM-DD')
    }
    
    this.requestModel.createRequest(this.requestData).subscribe({
      next:result => {
      this.spinner.hide()
      this.toast.success(this.translate.instant(this.editMode?"REQ_UPDATE_SUCCESS":"REQ_CREATED_SUCCESS"));
      this.clearForm();
    }
      , error:error => {
        this.spinner.hide()
        this.toast.error(this.translate.instant(error));
      }
      });
  }

  handlingDefaultDropdownValues(){
    this.requestData.requestTypeId=this.requestData.requestTypeId??0;
    this.requestData.destinationPlantId=this.requestData.destinationPlantId ?? 0;

  }
}
