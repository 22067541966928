import { Observable, Subject } from "rxjs";
import { DropDownMaster } from "../shared/dropdown-master.data";
import { PlantCodeMasterData, PlantCodeShippingData, RequestMasterData } from "./data/masterdata/request-master.data";
import { RequestMasterService } from "./request-master.service";

export abstract class RequestMasterModel
{

    constructor( private service:RequestMasterService)
    {
        console.log("")
    }
    
//Retrieve the Request form  Master 
public retrieveRequestArticleMaster():Observable<DropDownMaster[]>
{
   let subject=new Subject<DropDownMaster[]>();
    this.service.retrieveRequestArticleMaster().subscribe(response=>{
       let apiResponse:any= response;
       let responseResult:any=apiResponse.results;
       if(responseResult.status_id===200)
       {
           let result:DropDownMaster[]=[]
        apiResponse.data.forEach((element:any) => {
            let resultdata=new DropDownMaster();
            resultdata.label=element;
            resultdata.value=element;
            result.push(resultdata);
        })
        subject.next(result)
       }
       
    });
    return subject.asObservable();
}

//Retrieve the Request form  Master 
public retrieveRequestMaster(transportType:number):Observable<RequestMasterData>
{
   let subject=new Subject<RequestMasterData>();
    this.service.retrieveRequestMaster(transportType).subscribe(retrieveRequestMasterResponse=>{
       let apiResponse:any= retrieveRequestMasterResponse;
       let responseResult:any=apiResponse.results;
       if(responseResult.status_id===200)
       {
        this.responseOnSuccess(apiResponse, subject);
       }
       
    });
    return subject.asObservable();
}

public retrieveRequestMasterPlantCodes(transportType:number,isOrigin:boolean,plantCode:string):Observable<PlantCodeMasterData>
{
   let subject=new Subject<PlantCodeMasterData>();
   let plantcodeMasterData=new PlantCodeMasterData();
   plantcodeMasterData.originPlantCodeMaster = [];
   plantcodeMasterData.destinationPlantCodeMaster = [];
    this.service.retrieveRequestMasterPlantCodes(transportType,isOrigin,plantCode).subscribe(retrieveRequestMasterResponse=>{
       let apiResponse:any= retrieveRequestMasterResponse;
       let responseResult:any=apiResponse.results;
       if(responseResult.status_id===200)
       {
        let originPlant:DropDownMaster[]=[]
        let destPlant:DropDownMaster[]=[]
        if(apiResponse.data!=null && apiResponse.data.length!=0){
            apiResponse.data.forEach((element:any) => {
                let resultdata=new DropDownMaster();
                resultdata.label=element.masterValue;
                resultdata.value=element.masterId;
                if(element.isOriginPlantCode){
                    originPlant.push(resultdata)
                }
                else{
                    destPlant.push(resultdata)
                }           
            })
            plantcodeMasterData.plantShippingData=apiResponse.data;
            plantcodeMasterData.originPlantCodeMaster=originPlant;
            plantcodeMasterData.destinationPlantCodeMaster=destPlant;
            subject.next(plantcodeMasterData)
        }
        else{
            subject.error("INVALID_PLANT_CODE"); 
        }        
       }
       else{
       subject.error("INVALID_PLANT_CODE");
       }
    });
    return subject.asObservable();
}

public retrievePlantCodebyMasterId(transportType:number,plantCodeId:number):Observable<PlantCodeShippingData>{
    let subject=new Subject<PlantCodeShippingData>();
    this.service.retrievePlantCodebyMasterId(transportType,plantCodeId).subscribe(response=>{
        let apiResponse:any= response;
        let responseResult:any=apiResponse.results;
        if(responseResult.status_id===200)
        {
            if(apiResponse.data !=null && apiResponse.data.masterValue!=null && apiResponse.data.masterValue!=undefined){
                subject.next(apiResponse.data)
            }
            else{
                subject.error("INVALID_PLANT_CODE");  
            }        
        }
        else{
        subject.error("INVALID_PLANT_CODE");

        }
    });
    return subject.asObservable();
}



public  abstract responseOnSuccess(apiResponse: any, subject: Subject<RequestMasterData>):any;
    
//Convert  Response to dropdownMaster Array
protected  convertResponsetoArray(response:any, isIsasString:boolean):DropDownMaster[]
{
   let result:DropDownMaster[]=[];
   response.forEach((element:any) => {
      let resultdata=new DropDownMaster();
      if(!isIsasString)
      {
       resultdata.value=Number(element.masterId);
      }
      else
      {
        resultdata.value=(element.masterId);
      }
       resultdata.label=element.masterValue;
       result.push(resultdata);

    });
    return result;
}

// Process the common data for requestMaster
protected processCommonResult(apiResponse: any,datacollection :RequestMasterData) {
    let result: any = apiResponse.data;
 
    datacollection.countryMaster = this.convertResponsetoArray(result.countryMaster, true);
    datacollection.unitMaster = this.convertResponsetoArray(result.volumeUOM, false);
    datacollection.tempRequirementMaster = this.convertResponsetoArray(result.tempRequirementMaster, false);
    datacollection.requestTypeMaster = this.convertResponsetoArray(result.requestTypeMaster, false);
    return datacollection;
}
}
