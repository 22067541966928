import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";
import { RailRateRequestDetails } from "src/app/requests/data/formdata/rail-rate-request-details.data";
import { RailRequestFormModel } from "src/app/requests/rail-rate-request-form.model";
import { RequestFormModel } from "src/app/requests/request-form.model";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { RailRateVendorResponseForm } from "../../data/rail-rate-vendor-response-form.data";
import { VendorResponseFormData } from "../../data/vendor-response-form.data";
import { RailRateVendorResponseModel } from "../../rail-rate-vendor-response.model";
import { VendorResponseMasterModel } from "../../vendor-response-master.model";
import { VendorResponseModel } from "../../vendor-response.model";
import { VendorUpdateCommonComponent } from "../../request-vendor-form/vendor-update-common.component";
import { NgxSpinnerService } from "ngx-spinner";
import { formatDate } from '@angular/common';
import { TranslateService } from "@ngx-translate/core";
import { VendorApiSubmit } from "../../data/vendor-api-submit.data";
import * as moment from "moment";


@Component({
  selector: 'app-rail-rate-vendor-na',
  templateUrl: '..\\rail-rate-details-vendor-template.html',
  styleUrls: ['..\\rail-rate-details-vendor-template.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel,
    VendorResponseMasterModel,
    ConfirmationService,
    { provide: RequestFormModel, useClass: RailRequestFormModel },
    { provide: VendorResponseModel, useClass: RailRateVendorResponseModel }

  ]
})

export class RailRateVendorResponseComponentNA extends VendorUpdateCommonComponent implements OnInit {


translate:TranslateService;
spinner: NgxSpinnerService
  constructor(private userAccessModel: LoggedInUserInfoModel, private requestModel: RequestFormModel,
    private vendorMaster: VendorResponseMasterModel, private vendorModel: VendorResponseModel,_translate:TranslateService,
    private cService: ConfirmationService, private toast: ToastrService, private router: Router,
    private _spinner: NgxSpinnerService) {
    super(_translate,_spinner);
    this.translate=_translate;
    this.spinner=_spinner;
  }

  ngOnInit() {
    this.requestData = new RailRateRequestDetails();
    this.vendorObj = new RailRateVendorResponseForm();

    this.validateloggedInUser(this.userAccessModel, this.router);
    this.retrievevendorMaster(this.vendorMaster);
    this.retrieveVendorUserMaster(this.vendorMaster);
    this.retrievevendorCurrency(this.vendorMaster);
    this.retrieveRequestDetails(this.transportType, this.requestId, this.requestModel);

  }
  onRetrieveRequestCompleted() {
    this.retrieveVendorResponse(this.vendorModel);
  }

  onRetrieveVendorResponseCompleted() {
    this.reasonForSelection = ((this.requestData) as RailRateRequestDetails).reasonForSelection;
    this.rateReqCalData=((this.requestData)as RailRateRequestDetails).rateReqCalData;
    this.rateReqCalFileName=((this.requestData)as RailRateRequestDetails).rateReqCalFileName;
    this.rateReqCalFileExtn=((this.requestData)as RailRateRequestDetails).rateReqCalFileExtn;
  }


  vendorSelected(event: any, vendorId: any, rowdata: any) {

    rowdata.validVendor=true;
    rowdata.vendorUser=this.VendorUsersList.filter(ven=>ven.VendorId==rowdata.vendorId)
    this.vendorsList.forEach((element:any) => {
      if (element.value == vendorId) {
        rowdata.eMail = element.email;     
      }
    });
  }


  onfilterVendorName(rowData: any) {
    this.vendorsData.forEach((v:any) => {
      (v as RailRateVendorResponseForm).disable = true;
      if (v.vendorId == rowData.vendorId) {
        if(this.vendorsList.filter(v1 => v1.value == rowData.vendorId).length > 0)
        v.vendorName = this.vendorsList.filter(v1 => v1.value == rowData.vendorId)[0].label;
      else
      v.vendorName=rowData.vendorName
      }
      else if(v.vendorName==''){
        v.vendorName=this.vendorsList.find(x=>x.value==v.vendorId)!.label;
      }
    });
  }

  onVendorChange(event: any) {
    console.log('');

  }
  CreateNewVendorResponseRow() {
    let vendorObj=new RailRateVendorResponseForm();
    vendorObj.currencyType=this.requestData.currencyId; 
    this.onCreateNewVendorResponseRow(vendorObj)
  }
  addVendor(rowData:any) {
    let vendorObj=new RailRateVendorResponseForm();
    vendorObj.currencyType=this.requestData.currencyId;
    this.onAddVendor(rowData, this.toast ,vendorObj)
  }
  removeVendor(rowData:any) {
    this.onRemoveVendor(rowData);
  }

  openDeleteVendorPopup(rowData:any) {
    this.onOpenDeleteVendorPopup(rowData, this.cService)
  }

  bindVendorResponseResult(element: any) {
   
    return element as RailRateVendorResponseForm;
  }

  onSelectionChange(rData:any,field:any) {
    this.vendorsData.forEach((v:any)=>{
        if (v.vendorId == rData.vendorId && field=="isSelected") {
          v.isSelected = !v.isSelected;
          rData.rankAssigned=v.isSelected?rData.rankAssigned:0;
        }
    })
  }

  isVendorsDueDatePassed(){
    this.enableAwardingSubmit=true;
    this.enableVendorInputMode=false;
    let isdueDategreater:boolean=false;
    this.vendorsData.forEach(ven=>{
      if(ven.dueDate!==undefined && ven.dueDate!=null){       
        if(moment(moment(this.minDateValue).format('YYYY-MM-DD')).diff(moment(moment(ven.dueDate).format('YYYY-MM-DD')))<=0){
          isdueDategreater=true;
          this.enableVendorInputMode=true;
        }
      }
      else if(ven.bidAmount==0){
        isdueDategreater=true;
        this.enableVendorInputMode=true;
      }
      else{      
        this.enableVendorInputMode=true;
      }      
    })
    this.selectMinBidAmount(isdueDategreater)
   }

   selectMinBidAmount(isdueDategreater:boolean){
    if(isdueDategreater){
      this.enableAwardingSubmit=false;
    }
    if(this.enableAwardingSubmit){      
      let minBidAmount:any=0;
      minBidAmount=this.calculateMinimumBidAmount(minBidAmount);
      
      if(this.requestData.requestStatusId!==7 && minBidAmount){
        this.vendorsData.forEach(ven=>{
          if(ven.bidAmount==minBidAmount){
            ven.isSelected=true
          }          
        })
      }      
    }
   }

   calculateMinimumBidAmount(minBidAmount:any){
    this.vendorsData.forEach(ven=>{        
      if(ven.bidAmount>0){
        minBidAmount=minBidAmount==0?ven.bidAmount:minBidAmount;
        minBidAmount = minBidAmount>ven.bidAmount?ven.bidAmount:minBidAmount;
      }      
    }) 
    return minBidAmount;
   }
   rearrangeRanksonRemove(rowData:any){
    this.ranks = [];     

    for(let vd of this.vendorsData){
       //managing vendor first rank
      if(vd.rankAssigned> rowData.rankAssigned){
        vd.rankAssigned = vd.rankAssigned - 1;
      }    

      let rObj = {
        value: this.ranks.length + 1,
        label: (this.ranks.length + 1).toString()
      };
      this.ranks.push(rObj);      
    }
  } 

  notifyVendors(){
    this.spinner.show()
    let unsavedVendors:boolean=false;
    this.selectedVendors.forEach(vendor=>{
      if(!vendor.responseId){
        unsavedVendors=true;
      }
    })
    if(unsavedVendors){
      this.spinner.hide()
      this.toast.warning(this.translate.instant("UPDATE_VENDORS_BEFORE_NOTIFY"),this.translate.instant("WARNING"))
      return false;
    }
    else{
    this.selectedVendors.forEach(vendor=>{
      if(vendor.dueDate==undefined){
        vendor.dueDate=formatDate(this.calculateDueDate(vendor.dueDate),'yyyy-MM-dd','en-Us')
      }
      
    })

    this.selectedVendors = this.vendorModel.validateVendor(this.selectedVendors);
    this.selectedVendors = this.vendorModel.validateRank(this.ranks, this.selectedVendors);
    let _vendorData = this.selectedVendors as RailRateVendorResponseForm[];
    let invalidData = _vendorData.filter((v:any) => !v.validVendor || !v.validBidAmount  || !v.validComments || !v.validCurrency || !v.validDueDate );

      if (invalidData.length > 0) {
        this.spinner.hide()
        return false;
      }
      let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
      apisubmitdata.vendorsData=this.selectedVendors as VendorResponseFormData[];
      apisubmitdata.requestId=this.requestId;
      apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
      apisubmitdata.selectedReason=this.reasonForSelection;
      apisubmitdata.isconfirmed=false;
      apisubmitdata.requestStatus=this.requestData.requestStatusId;
      apisubmitdata.requestorMail=this.requestData.requesterMailId;
      apisubmitdata.transportType=this.transportType;
      apisubmitdata.modifiedBy=this.userAccessPermission.userId;

    this.vendorModel.notifyVendorResponse(apisubmitdata)
      .subscribe(
        {next:response => {        
        this.selectedVendors = [];
        this.vendorsData=[];
        this.retrieveVendorResponse(this.vendorModel)
        this.spinner.hide();
        this.toast.success(this.translate.instant("VENDOR_USERS_NOTIFIED_SUCCESSFULLY"), "Success");

      },
       error: error => {
          this.spinner.hide()
          this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"));
       }
        });
    
return true;
      }
  }

  submitBidByVendor(){
    this.spinner.show()
    let invalidData:boolean=false;
    
  
    this.vendorsData.forEach(ven=>{
      if(!ven.bidAmount){       
        invalidData=true;
      }
     
    })
    if(invalidData){
      this.spinner.hide()
      this.toast.warning(this.translate.instant("VALID_BID_AMOUNT_FOR_RATE"),this.translate.instant("WARNING"));
      return false;      
    }  
    else{
    
    let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
      apisubmitdata.vendorsData=this.vendorsData as VendorResponseFormData[];
      apisubmitdata.requestId=this.requestId;
      apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
      apisubmitdata.selectedReason=this.reasonForSelection;
      apisubmitdata.isconfirmed=false;
      apisubmitdata.requestStatus=this.requestData.requestStatusId;
      apisubmitdata.requestorMail=this.requestData.requesterMailId;
      apisubmitdata.transportType=this.transportType;
      apisubmitdata.modifiedBy=this.userAccessPermission.userId;
    
    this.vendorModel.submitBidByVendor(apisubmitdata)
    .subscribe(
      {next:response=>{
      if(response=='SUCCESS'){
        this.vendorsData=[]
        this.retrieveVendorResponse(this.vendorModel)
        this.spinner.hide()
        this.toast.success(this.translate.instant("BID_SUBMIT_SUCCESFUL"),this.translate.instant("SUCCESS"));
      }      
      else{
        this.spinner.hide()
        this.toast.warning(this.translate.instant(response),"warning")
      }
    },
    error:error=>{
      this.spinner.hide()
      this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"));
    }
  }
    )
return true;
  }
  }

  updateVendors() {
    this.spinner.show();
    let noUserSelected:boolean=false;
    if (!this.clearVendors) {
      this.vendorsData = this.vendorModel.validateVendor(this.vendorsData);
      this.vendorsData = this.vendorModel.validateRank(this.ranks, this.vendorsData);
      let _vendorData = this.vendorsData as RailRateVendorResponseForm[];
      let invalidData = _vendorData.filter((v:any) => !v.validVendor || !v.validBidAmount  || !v.validComments || !v.validRank || !v.validCurrency || !v.validDueDate );
      if (invalidData.length > 0) {
        this.spinner.hide()
        return;
      }      
      
    }
    this.vendorsData.forEach(vendor=>{
      if(vendor.dueDate==undefined){
        vendor.dueDate=formatDate(this.calculateDueDate(vendor.dueDate),'yyyy-MM-dd','en-Us')
      }
      vendor.rankAssigned=vendor.rankAssigned??0;           
    })

    let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
    apisubmitdata.vendorsData=this.vendorsData as VendorResponseFormData[];
    apisubmitdata.requestId=this.requestId;
    apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
    apisubmitdata.selectedReason=this.reasonForSelection;
    apisubmitdata.isconfirmed=false;
    apisubmitdata.requestStatus=this.requestData.requestStatusId;
    apisubmitdata.requestorMail=this.requestData.requesterMailId;
    apisubmitdata.transportType=this.transportType;
    apisubmitdata.modifiedBy=this.userAccessPermission.userId;

    this.vendorModel.submitvendorResponse(apisubmitdata,"","","")
      .subscribe(
        {next:response => {  
        this.vendorsData = [];
        this.selectedVendors=[];
        this.ranks=[]
        this.retrieveRequestDetails(this.transportType,this.requestId,this.requestModel);
        this.refresh.emit('update');
        this.spinner.hide()
        if(response=='SUCCESS')
        this.toast.success(this.translate.instant("VENDORS_UPDATE_SUCCESSFUL"),this.translate.instant("SUCCESS"));
        else{
          this.toast.warning(this.translate.instant(response),"warning")
        }  

      },
       error: error => {
          this.spinner.hide()
          this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"));
       }
        });
  }

  canelSubmit() {
    this.spinner.show()
    this.vendorsData = [];
    this.selectedVendors=[];
    this.ranks=[];
    this.retrieveVendorResponse(this.vendorModel);
    this.spinner.hide();
  }

  override cancelvendorSubmit(){   
    this.router.navigateByUrl('/request-list')
 }

  openCofirmPopup() {
    this.cService.confirm({
      message: this.translate.instant('SURE_TO_PROCEED'),
      header: this.translate.instant('CONFIRMATION'),
      accept: () => {
        this.ConFirmVendor();
      }
    });
  }


  ConFirmVendor() {
    this.spinner.show()
    this.vendorsData = this.vendorModel.validateVendor(this.vendorsData);
    this.vendorsData = this.vendorModel.validateRank(this.ranks, this.vendorsData);


    let _vendorData = this.vendorsData as VendorResponseFormData[];
    let invalidData = _vendorData.filter((v:any) => !v.validVendor || !v.validBidAmount || !v.validComments || !v.validRank || !v.validCurrency);

    if (invalidData.length > 0) {
      this.spinner.hide()
      this.toast.warning(this.translate.instant("CHECK_VENDOR_DETAILS"),this.translate.instant("WARNING"));
      return false;
    }



    this.validReason = this.reasonForSelection != null && this.reasonForSelection != undefined && this.reasonForSelection != "";
    if (!this.validReason) {
      this.spinner.hide()
      this.toast.warning(this.translate.instant("VAL_REASON_FOR_SELCTION"),this.translate.instant("WARNING"))
      return false;
    }
    else{

    let apisubmitdata:VendorApiSubmit=new VendorApiSubmit();
    apisubmitdata.vendorsData=this.vendorsData as VendorResponseFormData[];
    apisubmitdata.requestId=this.requestId;
    apisubmitdata.referenceNumber=this.requestData.requestRefNumber;
    apisubmitdata.selectedReason=this.reasonForSelection;
    apisubmitdata.isconfirmed=true;
    apisubmitdata.requestStatus=this.requestData.requestStatusId;
    apisubmitdata.requestorMail=this.requestData.requesterMailId;
    apisubmitdata.transportType=this.transportType;
    apisubmitdata.modifiedBy=this.userAccessPermission.userId;

    this.vendorModel.submitvendorResponse(apisubmitdata,this.rateReqCalData,this.rateReqCalFileName,this.rateReqCalFileExtn)
      .subscribe(
        {next:confirmVendorResponse => {
        this.vendorsData = [];
        this.ranks=[]
        this.retrieveRequestDetails(this.transportType,this.requestId,this.requestModel);
        this.refresh.emit('submit');
        this.spinner.hide()
        if(confirmVendorResponse=='SUCCESS')
        this.toast.success(this.translate.instant("VENDORS_AWARD_DONE"),this.translate.instant("SUCCESS"));
        else{
          this.toast.warning(this.translate.instant(confirmVendorResponse),this.translate.instant("WARNING"))
        }
      },
        error:error => {
          this.spinner.hide()
          this.toast.error(this.translate.instant(error),this.translate.instant("ERROR"));
        }
        });

return true;
      }

  }





}
