import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { PackageContainerBoxDrayageDetails } from "../data/formdata/package-container-box-drayage-request-details.data";
import { LiquidTruckRequestFormModel } from "../liquid-request-form.model";
import { RequestFormModel } from "../request-form.model";
import { RequestDetailsComponent } from "./request-details-form.component";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";

@Component({
    selector: 'app-package-container-dryage-request-details',
    templateUrl: '..\\shared-templates\\request-details-form-template.html',
    styleUrls: ['..\\shared-templates\\request-details-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel,
        { provide: RequestFormModel, useClass: LiquidTruckRequestFormModel }
    ]
})
export class PackagaContainerBoxDetailsRequestComponent extends RequestDetailsComponent implements OnInit,OnDestroy {

   
    private subscriptionName: Subscription; //important to create a subscription
    messageReceived: any;
    pageLanguage:string="";
    override requestData:any
    constructor(userAccessModel: LoggedInUserInfoModel,  requestModel: RequestFormModel,
        toast: ToastrService, router: Router, spinner: NgxSpinnerService, route: ActivatedRoute,private _appServ:AppServiceService ) {
        super(userAccessModel,requestModel, toast, router, spinner,route);
        this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
            this.messageReceived = message;        
            let newLang=sessionStorage.getItem('selectedLanguage');
            if(this.pageLanguage!=newLang && this.pageLanguage!=""){
              this.ngOnInit();
            }
            });
    }

    ngOnInit(): void {
        this.pageLanguage=sessionStorage['selectedLanguage'];
        this.requestData = new PackageContainerBoxDrayageDetails();
        this.transportType=6
        this.validateloggedInUser();
        this.readQueryString();
        
        this.retrieveHeaderValues();
        this.bindfiledVisiblity();
        
    }
    downLoadFISPQFile(){console.log('')}
    downLoadSpecialReqAttachment(){console.log('')};
    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
        this.subscriptionName.unsubscribe();
    }

    bindfiledVisiblity()
    {
        this.fieldVisiblity.requestType = true;
        this.fieldVisiblity.startDate = true;
        this.fieldVisiblity.requestor = true;
        this.fieldVisiblity.bussinessunit = true;
        this.fieldVisiblity.articleNumber = true;
        this.fieldVisiblity.product = true;

        // Service Provider
        this.fieldVisiblity.monthlyVolume = true;
        this.fieldVisiblity.annualVolume = true;
        this.fieldVisiblity.dropContainerId = true;
        this.fieldVisiblity.tempRange = true;
        this.fieldVisiblity.tempControl = true;
        this.fieldVisiblity.hazardous = true;
        this.fieldVisiblity.loadingHrs = true;
        this.fieldVisiblity.unloadingHrs = true;
        this.fieldVisiblity.weekendDelivery = true;
        this.fieldVisiblity.unNumber=true;
        this.fieldVisiblity.isMaterialPackagedLiquidInTotes=true;

        //Shipping->Orgin
        this.fieldVisiblity.shippingName = true;
        this.fieldVisiblity.orginCity = true;
        this.fieldVisiblity.orginState = true;
        this.fieldVisiblity.orginCountry = true;
        this.fieldVisiblity.orginZipCode = true;
        this.fieldVisiblity.orginLocationType= true;
        //shipping-> destination
        this.fieldVisiblity.consigneeName = true;
        this.fieldVisiblity.destinationCity = true;
        this.fieldVisiblity.destinationState = true;
        this.fieldVisiblity.destinationCountry = true;
        this.fieldVisiblity.destinationZipCode = true;
        this.fieldVisiblity.destinationLocationType= true;

        //Additional Info
        this.fieldVisiblity.customerPreferedCarrier = true;
        this.fieldVisiblity.costVsService = true;
        this.fieldVisiblity.comments = true;

        this.fieldVisiblity.materialDoc = true;
        this.fieldVisiblity.materialLink = true;
        this.fieldVisiblity.sdsLink = true;
        this.fieldVisiblity.sdsAttachment = true;
        this.fieldVisiblity.additionalAttachment = true;
        this.fieldVisiblity.additionalAttachment2 = true;

        if(this.isVendorUser){
            this.fieldVisiblity.requestor=false;
            this.fieldVisiblity.customerPreferedCarrier=false;
            this.fieldVisiblity.costVsService=false;
          }
      
    }
    
    retrieveHeaderValues() {
        this.title = "Package Drayage";
      }
    
    bindConditionalFields(){
        console.log("")    
    }
      
}