import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { CleaningCategoryModel } from "../cleaning-category.model";
import { CleaningCategoryFormData } from "../data/cleaning-category-form.data";


@Component({
  selector: 'app-admin-cleaning-category-management',
  templateUrl: './admin-cleaning-category.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel,
    CleaningCategoryModel
  ]
})

export class AdminCleaningCategoryManagementComponent implements OnInit {

  @Input() loggedinUserinfo!: LoggecInUserInfo;

  cleaningCategorycollection: CleaningCategoryFormData[] = [];
  cleaningCategoryData: CleaningCategoryFormData = new CleaningCategoryFormData();

  statusOpts: any;

  cleaningCategoryTableColumns: any;
  constructor(private cleaninCategoryModel: CleaningCategoryModel,
    private spinner: NgxSpinnerService,
    private toast: ToastrService) {

  }
  ngOnInit(): void {

    this.retrieveCleaningCategoryTableColumn();
    this.retrieveCleaningCategoryStatus();
    this.retrieveCleaningCategoryCollection();
  }

  retrieveCleaningCategoryTableColumn() {
    this.cleaningCategoryTableColumns = this.cleaninCategoryModel.retrieveCleaningCategoryColumn();
    const screenWidth = window.innerWidth;
    if(screenWidth < 750) {
      this.cleaningCategoryTableColumns.forEach((col:any) => {
          col.width = (parseInt(col.width.replace('%','')) * 10).toString() + 'px';
      });
    }
  }
  saveCleaningCategory()
  {
    this.spinner.show();
    this.cleaningCategoryData=this.cleaninCategoryModel.validateClenaingCategoryForm(this.cleaningCategoryData);
    if (!(this.cleaningCategoryData.validMFGDesc && this.cleaningCategoryData.validMFGCode && this.cleaningCategoryData.validCCCode && this.cleaningCategoryData.validCCFee && this.cleaningCategoryData.validFreightClass)) {
      this.spinner.hide();
      return false;
    }
    else{
    this.cleaninCategoryModel.onSaveCleaningCategory(this.cleaningCategoryData,this.loggedinUserinfo.userId)
    .subscribe({
      next:saveResponse=>{
      this.toast.success(saveResponse, "Success");
      this.retrieveCleaningCategoryCollection();
      this.spinner.hide();
      this.onCancel();
    },
    error:(error)=>
    {
      this.toast.error(error, "Error!");
      this.spinner.hide();
      return false;
    }});
    return true;
  }

  }
  onCancel()
  {

    this.cleaningCategoryData=new CleaningCategoryFormData();
  }

  onRowCCEditInit(rowData:any) {
    this.cleaningCategoryData.editing = true;
    this.cleaningCategoryData.mfg_id= rowData.mfg_id;
    this.cleaningCategoryData.mfg_description = rowData.mfg_description;
    this.cleaningCategoryData.mfg_code = rowData.mfg_code;
    this.cleaningCategoryData.cleaning_category_code = rowData.cleaning_category_code;
    this.cleaningCategoryData.new_rate = rowData.new_rate;
    this.cleaningCategoryData.freight_class = rowData.freight_class;
    this.cleaningCategoryData.is_active = rowData.is_active;
  }

  retrieveCleaningCategoryCollection()
  {
    this.cleaninCategoryModel.retrieveCleaningCategoryCollection(this.loggedinUserinfo.userId)
    .subscribe({
    next:resultCollection=>{
     this. cleaningCategorycollection=resultCollection
      },
    error:(error)=>
    {
      console.error(error);
    }})
  }

  retrieveCleaningCategoryStatus() {
    this.statusOpts = this.cleaninCategoryModel.retrieveCleaningCategoryStatus();
  }

  //return ;
} 
