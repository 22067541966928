import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { LiquidTruckRequestFormData } from "../../../data/formdata/liquid-request-form.data";
import { PlantCodeMasterData, RequestMasterData } from "../../../data/masterdata/request-master.data";
import { LiquidTruckRequestMasterModel } from "../../../liquid-request-master.model";
import { RequestMasterModel } from "../../../request-master.model";
import { CommonRequestComponent } from "../../request-form-common.component";
import { ProductModel } from "src/app/shared/product/products.model";
import { RequestFormModel } from "../../../request-form.model";
import { LiquidTruckRequestFormModel } from "../../../liquid-request-form.model";
import { Subscription } from "rxjs";
import { AppServiceService } from "src/app/services/app-service.service";
import { TranslateService } from "@ngx-translate/core";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { FileUpload } from "primeng/fileupload";


@Component(
  {
    selector: 'app-liquid-request-sa-form',
    templateUrl: '..\\..\\..\\shared-templates\\request-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel, ProductModel,
      { provide: RequestFormModel, useClass: LiquidTruckRequestFormModel },
      { provide: RequestMasterModel, useClass: LiquidTruckRequestMasterModel },
      FileUpload

    ]
  }
)

export class LiquidRequestFormSAComponent extends CommonRequestComponent implements OnInit,OnDestroy {

  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;

  override requestData:any;
  override requestMaster:any;
  // override requestMaster: RequestMasterData = new RequestMasterData;
  // override requestData: LiquidTruckRequestFormData = new LiquidTruckRequestFormData;
  _toast: ToastrService;
  _translate:TranslateService;
  pageLanguage:string=""
  override is_FISPQ_fileUpload :boolean=false;
  selectedtruckIds!: any[];
  

  constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
    private productModel: ProductModel, requestModel: RequestFormModel,
    toast: ToastrService, router: Router, spinner: NgxSpinnerService,translate:TranslateService,
    private spinners: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService,public fileUpload: FileUpload) {
    super(userAccessModel, masterModel, requestModel, router, toast, spinner, route,translate);
    this._toast = toast;
    this._translate=translate;
    this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
    this.messageReceived = message;
    
    let newLang=sessionStorage.getItem('selectedLanguage');

    if(this.pageLanguage!=newLang && this.pageLanguage!=""){
      this.ngOnInit();
    }
    });
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
}






  ngOnInit(): void {
    this.pageLanguage=sessionStorage['selectedLanguage'];
    this.transportType = 1;
    this.startDateLable = "START_DATE";

    this.requestMaster = new RequestMasterData();
    this.plantCodeShippingDetails=new PlantCodeMasterData();
    this.requestData = new LiquidTruckRequestFormData();
    this.requestData.transportType = 1;
    this.bindfiledVisiblity();
    this.retrieveHeaderValues();
    //login
    this.validateloggedInUser();
    //Request Master
    this.retrieveMasterData(this.transportType);
    //plantcode with shipping data
    this.retrievePlantCodeMasterData(this.transportType);
    //Article
    this.retrieveArticleList(this.spinners)
    //retrieve Query Parameters
    this.retrieveQueryPramaters();
    this.retriveLang(this.pageLanguage);   

  }
  priorProdDetailsRestrictions() {
    if(this.requestData.priorProductRestriction=='Yes' && this.fieldVisiblity.priorProductRestriction)
    this.fieldVisiblity.priorProdDetailsRestrictions = true;
  }
  bindfiledVisiblity() {
    this.fieldVisiblity.requestType = true;
    this.fieldVisiblity.startDate = true;
    this.fieldVisiblity.requestor = true;
    this.fieldVisiblity.bussinessunit = true;
    this.fieldVisiblity.articleNumber = true;
    this.fieldVisiblity.product = true;
    this.fieldVisiblity.mfg = false;
    this.fieldVisiblity.freightClass = false;
    this.fieldVisiblity.cleaninCost = false;
    this.fieldVisiblity.interModel = true;
    this.fieldVisiblity.equipmentType = true;

    // Service Provider
    this.fieldVisiblity.volumeType = true;
    this.fieldVisiblity.annualVolume = true;
    this.fieldVisiblity.monthlyVolume = false;
    this.fieldVisiblity.centerRearUnload = false;
    this.fieldVisiblity.dropTrail = false;
    this.fieldVisiblity.days = false;
    this.fieldVisiblity.tempRange = true;
    this.fieldVisiblity.tempRequirement = true;
    this.fieldVisiblity.priorProductRestriction = true;
    this.fieldVisiblity.hazardous = false;
    this.fieldVisiblity.kosher = false;
    this.fieldVisiblity.materialTopLoad = true;
    this.fieldVisiblity.foodGradeWash = false;
    this.fieldVisiblity.loadingHrs = false;
    this.fieldVisiblity.unloadingHrs = false;
    this.fieldVisiblity.nitrogenBlanket = true;
    this.fieldVisiblity.weekendDelivery = false;
    this.fieldVisiblity.groundVapourRecovery = false;
    this.fieldVisiblity.centerRear = false;
    this.fieldVisiblity.cleaningProcedures = true;
    this.fieldVisiblity.mandDocsInsideTruck = true;
    this.fieldVisiblity.mandDocsInsideTruckDoc=false;
    this.fieldVisiblity.mandDocsInsideTruckSa=true;

    this.fieldVisiblity.averageProduct = true;

    //Shipping->Orgin
    this.fieldVisiblity.originPlant = true;
    this.fieldVisiblity.shippingName = true;
    this.fieldVisiblity.orginCity = true;
    this.fieldVisiblity.orginState = true;
    this.fieldVisiblity.orginCountry = true;
    this.fieldVisiblity.orginZipCode = true;
    //shipping-> destination
    this.fieldVisiblity.destinationPlant = true;
    this.fieldVisiblity.consigneeName = true;
    this.fieldVisiblity.destinationCity = true;
    this.fieldVisiblity.destinationState = true;
    this.fieldVisiblity.destinationCountry = true;
    this.fieldVisiblity.destinationZipCode = true;

    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier = false;
    this.fieldVisiblity.costVsService = false;
    this.fieldVisiblity.comments = true;

    this.fieldVisiblity.materialDoc = true;
    this.fieldVisiblity.materialLink = true;
    this.fieldVisiblity.sdsLink = true;
    this.fieldVisiblity.sdsAttachment = true;
    this.fieldVisiblity.additionalAttachment = true;
    this.fieldVisiblity.additionalAttachment2 = true;

  }
  onAnnualVolumeChange(event: any){
    console.log("");
   

  }
  override onmandadoryDocsInsideTruck(event: any){
    if(event.target.value=='Yes'){    this.OnRemoveFISPQAttachment()
      
    }
    this.mandatorydocsfunction();
  }
  mandatorydocsfunction(){

    if(this.requestData.mandadoryDocsInsideTruck=="Yes" && this.requestData.mandadoryDocsInsideTruckIdSelected.includes(1))
      {
       this.fieldVisiblity.mandDocsInsideTruckDoc=true;
      }
      else{
        this.fieldVisiblity.mandDocsInsideTruckDoc=false;
        this.OnRemoveFISPQAttachment()
      }
  }
  override onmandDocsChangeEvent(event: any){
     if(this.requestData.mandadoryDocsInsideTruck=="Yes" && event.value.includes(1))
 {
  this.fieldVisiblity.mandDocsInsideTruckDoc=true;
 } else{
  this.fieldVisiblity.mandDocsInsideTruckDoc=false;
} 

  }
  override onArticleClear(event:any) {
    this.resetArticleData();
  }

  onArticleComplete(event:any) {
    super.retrieveProductDetails(event)
  }
  onArticleSelected(event:any) {
    this.processArticleNumber(event);

  }

  override onPriorProductRestrionChange(event:any)
  {

    if(event.target.value == "Yes"){
      this.fieldVisiblity.priorProdDetailsRestrictions = true;
    }
    else{
      this.fieldVisiblity.priorProdDetailsRestrictions = false;
      this.requestData.priorProdDetailsRestrictions = '';
    }
  }

  retrieveHeaderValues() {
    this.title = "Liquid Truck";
    this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
  }
  resetArticleData() {
    this.requestData.mfg = "";
    this.selectedMFGCode = "";
    this.requestData.freighClass = "";
    this.requestData.cleaningCost = "";
    this.selectedArticle = [];
    this.requestData.product = "";
    this.requestData.articleNumber = "";

  }
  GenerarateArticleNumberEvent() {
    this.selectedArticle.label = this.requestData.articleNumber;
    return {
      label: this.requestData.articleNumber
    };
  }
  handlehazardousChange(event:any) {
    console.log("");

  }
  processArticleNumber(event: any) {
    this.requestData.articleNumber = event.label;
    this.productModel.retrieveProductForArticle(event.label).subscribe({
      next:result => {
      this.requestData.articleNumber = result.articleNumber;
      this.requestData.cleaningCost = result.newRate;
      this.requestData.freighClass = result.friegeClass;
      this.requestData.mfg = result.mfg;
      this.requestData.product = result.product;
      this.selectedMFGCode = result.mfgCode;
    }, 
    error:error => {
      this._toast.error(this._translate.instant(error),this._translate.instant("ERROR"))
    }
    });

  }
  public clearForm() {
    this.OnCancel();
  }
  override OnCancel() {

    this.clearData(1);
    this.requestData.articleNumber = "";
    this.requestData.cleaningCost = "";
    this.requestData.freighClass = "";
    this.requestData.internModel = "";
    this.requestData.mfg = "";
    this.requestData.equipmentTypeId=0;

    //Service provider
    this.requestData.days = 0;
    this.requestData.dropTrail = "";
    this.requestData.monthlyVolume = 0;
    this.requestData.monthlyVolumeUnitId = 0;
    this.requestData.centerRear = "";
    this.requestData.centerRearUnload = "";
    this.requestData.foodGradeWash = "";
    this.requestData.groundVapourDelivery = "";
    this.requestData.hazardous = "";
    this.requestData.kosher = "";
    this.requestData.loadingHours = ""
    this.requestData.materialTopLoad = "";
    this.requestData.nitrogenBlanket = "";
    this.requestData.priorProductRestriction = "";
    this.requestData.priorProductRestrictionDetails="";
    this.requestData.tempRange = "";
    this.requestData.tempRequirementId = 0;
    this.requestData.unloadingHour = "";
    this.requestData.weekendDelivery = "";
    this.requestData.cleaningProcedures="";
    this.requestData.mandadoryDocsInsideTruck="";
    this.requestData.mandadoryDocsInsideTruckId=0;
    this.requestData.averageProduct="";
    this.requestData.averageProductId=0;
    this.resetArticleData();
    this.fileUpload.clear();
    this.requestData.mandadoryDocsInsideTruckIdSelected=0;
      this.OnRemoveFISPQAttachment()
  }

  onSubmitRequest() {
    this.handlingDefaultDropdownValues();
    this.requestData.equipmentTypeId=this.requestData.equipmentTypeId??0;
    this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;
    this.requestData.averageProductId=this.requestData.averageProductId??0;

    if (this.requestData.monthlyVolume == 0 || this.requestData.monthlyVolume == undefined || this.requestData.monthlyVolume == null) {
      this.requestData.monthlyVolume = 0;
    }
    if (this.requestData.annualVolume == 0 || this.requestData.annualVolume == undefined || this.requestData.annualVolume == null) {
      this.requestData.annualVolume = 0;
    }

    if (this.requestData.campaignSeasonalVolume == 0 || this.requestData.campaignSeasonalVolume == undefined || this.requestData.campaignSeasonalVolume == null) {
      this.requestData.campaignSeasonalVolume = 0;
    }

    if (this.requestData.days == 0 || this.requestData.days == undefined || this.requestData.days == null) {
      this.requestData.days = 0;
    }

    let updatemandadoryDocsInsideTruckId: { masterId: any; masterValue: string; masterOrderBy: number; }[] = [];
    if(this.requestData.mandadoryDocsInsideTruckIdSelected){
    this.requestData.mandadoryDocsInsideTruckIdSelected.forEach((element :any)=> {
      let dropdownData = {
          masterId: element,
          masterValue: "",
          masterOrderBy: 0
      };

      updatemandadoryDocsInsideTruckId.push(dropdownData);
  });
}
      this.requestData.mandadoryDocsInsideTruckDetails= updatemandadoryDocsInsideTruckId;
    this.validateMasterData();
    this.createRequest();
  }

  validateMasterData(){
    if(this.requestData.tempRequirementId!=0
      && !this.requestMaster.tempRequirementMaster.find((t:any)=>t.value==this.requestData.tempRequirementId))
    {
     this.requestData.tempRequirementId=0;
    }
  }

  requestTypeChangeEvent(event:any) {
    console.log("");
  }

  bindConditionalFields(){
    let LiquidReqData=this.requestData;
   
        if (LiquidReqData.FISPQFileName != undefined && LiquidReqData.FISPQFileName != "") {

          this.is_FISPQ_fileUpload = true;
        }
        if(this.requestData.mandadoryDocsInsideTruckDetails.length>0)
        this.selectedtruckIds=[];
        this.requestData.mandadoryDocsInsideTruckDetails.forEach((element:any)=>
          {
            let resultdata=new DropDownMaster();
            resultdata.value=Number(element.masterId);
          
            resultdata.label=element.masterValue;
            this.selectedtruckIds.push(resultdata.value);
            
          })
          this.requestData.mandadoryDocsInsideTruckIdSelected= this.selectedtruckIds;

          if(this.requestData.mandadoryDocsInsideTruck=="Yes" && this.selectedtruckIds.includes(1))
          {
           this.fieldVisiblity.mandDocsInsideTruckDoc=true;
          } else{
           this.fieldVisiblity.mandDocsInsideTruckDoc=false;
         } 
         this.priorProdDetailsRestrictions();
         this.mandatorydocsfunction();
  }
  onPlantSelectPopulate(event:any,index:number){}
  bindMandatoryTrukData(data:any){
    
    data.forEach((element:any)=>
      {
        let resultdata=new DropDownMaster();
        resultdata.value=Number(element.masterId);
      
        resultdata.label=element.masterValue;
        this.requestData.mandadoryDocsInsideTruckIdSelected.push(resultdata.value);
        this.requestData.mandadoryDocsInsideTruckIdSelected= this.requestData.mandadoryDocsInsideTruckDetails;
        
      })
    }
  override OnFISPQFileUpload(event:any,fileUpload: FileUpload) {
    this.spinners.show();
    let fileData = event.files[0];
    const element = event.files[0];
    if (element.size > 3145728) {
      this.spinners.hide();
      this._toast.warning(this._translate.instant("FILE_LIMIT_EXCEEDED"));
      fileUpload.clear();
      this.OnRemoveFISPQAttachment()
      return false;
    } else {
      this.requestData.FISPQFileName = fileData.name;
      this.requestData.FISPQFileExtension = fileData.type;
      this.requestData.FISPQAttachment = "";
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.requestData.FISPQAttachment = fileReader.result;
        this.requestData.FISPQAttachment = this.requestData.FISPQAttachment.replace(`data:${this.requestData.FISPQFileExtension};base64,`, '');
        this.is_FISPQ_fileUpload = true;
      }
      fileReader.readAsDataURL(fileData);
      this.spinners.hide();
      return true;
    }

  }
  onSpecialRequirement(event:any){  }
  override OnRemoveFISPQAttachment() {
    this.requestData.FISPQAttachment = "";
    this.requestData.FISPQFileName = "";
    this.requestData.FISPQFileExtension = "";
    this.is_FISPQ_fileUpload = false;
  }
}