import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { RequestMasterModel } from "../request-master.model";
import { CommonRequestComponent } from "./request-form-common.component";
import { ProductModel } from "src/app/shared/product/products.model";
import { RequestFormModel } from "../request-form.model";
import { LiquidTruckRequestFormModel } from "../liquid-request-form.model";
import { ISODryageTruckMasterModel } from "../iso-dryage-truck-master.model";
import { ISODryageTruckFormData } from "../data/formdata/iso-dryage-truck-form.data";
import { TranslateService } from "@ngx-translate/core";
import { AppServiceService } from "src/app/services/app-service.service";
import { Subscription } from "rxjs";
import { ISODryageTruckMasterData } from "../data/masterdata/iso-dryage-truck-master.data";


@Component(
  {
    selector: 'app-liquid-request-form',
    templateUrl: '..\\shared-templates\\request-form-template.html',
    styleUrls: ['..\\shared-templates\\request-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel, ProductModel,
      { provide: RequestFormModel, useClass: LiquidTruckRequestFormModel },
      { provide: RequestMasterModel, useClass: ISODryageTruckMasterModel }

    ]
  }
)

export class ISODryageTruckComponent extends CommonRequestComponent implements OnInit,OnDestroy {

  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;

  // override requestMaster: ISODryageTruckMasterData = new ISODryageTruckMasterData;
  // override requestData: ISODryageTruckFormData = new ISODryageTruckFormData;

  override requestMaster:any
 override requestData:any
  _toast: ToastrService;
  _translate:TranslateService
  pageLanguage: string="";


  constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
    private productModel: ProductModel, requestModel: RequestFormModel,
    toast: ToastrService, router: Router, spinner: NgxSpinnerService,
    private spinners: NgxSpinnerService, route: ActivatedRoute,translate:TranslateService,private _appServ: AppServiceService) {
    super(userAccessModel, masterModel, requestModel, router, toast, spinner, route,translate);
    this._toast = toast;
    this._translate=translate;
    this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
          this.messageReceived = message;    
          this.ngOnInit();
        });
  }

  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscriptionName.unsubscribe();
  }


  ngOnInit(): void {
    this.pageLanguage=sessionStorage['selectedLanguage'];      
    this.transportType = 5;
    this.startDateLable = "START_DATE";
    this.pageLanguage=sessionStorage['selectedLanguage'];
    this.requestMaster = new ISODryageTruckMasterData();
    this.requestData = new ISODryageTruckFormData();
    this.requestData.transportType = 5;
    this.bindfiledVisiblity();
    this.retrieveHeaderValues();
    //login
    this.validateloggedInUser();
    //Request Master
    this.retrieveMasterData(this.transportType);
    //Article
    this.retrieveArticleList(this.spinners)
    //retrieve Query Parameters
    this.retrieveQueryPramaters();
    this.retriveLang(this.pageLanguage);
  }
  
  bindfiledVisiblity() {
    this.fieldVisiblity.requestType = true;
    this.fieldVisiblity.startDate = true;
    this.fieldVisiblity.requestor = true;
    this.fieldVisiblity.bussinessunit = true;
    this.fieldVisiblity.articleNumber = true;
    this.fieldVisiblity.product = true;
    this.fieldVisiblity.mfg = true;

    // Service Provider
    this.fieldVisiblity.monthlyVolume = true;
    this.fieldVisiblity.annualVolume = true;
    this.fieldVisiblity.tempRange = true;
    this.fieldVisiblity.tempRequirement = true;
    this.fieldVisiblity.deliveryType = true;
    this.fieldVisiblity.hazardous = true;
    this.fieldVisiblity.loadingHrs = true;
    this.fieldVisiblity.nitrogenBlanket = true;
    this.fieldVisiblity.weekendDeliveryYN = true;

    //Shipping->Orgin
    this.fieldVisiblity.shippingName = true;
    this.fieldVisiblity.orginCity = true;
    this.fieldVisiblity.orginState = true;
    this.fieldVisiblity.orginCountry = true;
    this.fieldVisiblity.orginZipCode = true;
    //shipping-> destination
    this.fieldVisiblity.consigneeName = true;
    this.fieldVisiblity.destinationCity = true;
    this.fieldVisiblity.destinationState = true;
    this.fieldVisiblity.destinationCountry = true;
    this.fieldVisiblity.destinationZipCode = true;

    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier = true;
    this.fieldVisiblity.costVsService = true;
    this.fieldVisiblity.comments = true;

    this.fieldVisiblity.materialDoc = true;
    this.fieldVisiblity.materialLink = true;
    this.fieldVisiblity.sdsLink = true;
    this.fieldVisiblity.sdsAttachment = true;
    this.fieldVisiblity.additionalAttachment = true;
    this.fieldVisiblity.additionalAttachment2 = true;

  }

  override onArticleClear(event:any){
    this.resetArticleData();
  }

  onSpecialRequirement(event:any){

  }
  onArticleComplete(event:any){
    super.retrieveProductDetails(event)
  }
  onArticleSelected(event:any){
    this.processArticleNumber(event);

  }

  retrieveHeaderValues() {
    this.title = "ISO Tank Drayage";
    this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
  }
  resetArticleData() {
    this.requestData.mfg = "";
    this.selectedMFGCode = "";

    this.selectedArticle = [];
    this.requestData.product = "";
    this.requestData.articleNumber = "";

  }
  GenerarateArticleNumberEvent() {
    this.selectedArticle.label = this.requestData.articleNumber;
    return {
      label: this.requestData.articleNumber
    };
  }
  handlehazardousChange(event:any){
    console.log("");

  }
  processArticleNumber(event: any) {
    this.requestData.articleNumber = event.label;
    this.productModel.retrieveProductForArticle(event.label).subscribe({
      next: result => {
      this.requestData.articleNumber = result.articleNumber;
      this.requestData.mfg = result.mfg;
      this.requestData.product = result.product;
      this.selectedMFGCode = result.mfgCode;
    }, error:
    error => {
      this._toast.error(this._translate.instant(error),this._translate.instant("ERROR"))
    }
    });

  }
  public clearForm() {
    this.OnCancel();
  }
  override OnCancel() {
    this.clearData(5);
    this.requestData.articleNumber = "";
    this.requestData.internModel = "";
    this.requestData.mfg = "";

    //Service provider
    this.requestData.monthlyVolume = 0;
    this.requestData.monthlyVolumeUnitId = 0;
    this.requestData.hazardous = "";
    this.requestData.loadingHours = ''
    this.requestData.nitrogenBlanket = "";
    this.requestData.tempRequirementId = 0;
    this.requestData.tempRange="";
    this.requestData.deliveryType=0;
    
    this.requestData.weekendDelivery="";
    this.resetArticleData();
  }
  onAnnualVolumeChange(event: any){
    console.log(event.target.value);
  }
  onSubmitRequest() {
    this.handlingDefaultDropdownValues();
    this.requestData.monthlyVolumeUnitId=this.requestData.monthlyVolumeUnitId??0;
    this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;


    if(this.requestMaster.delivery_type_master.find((i:any)=>i.value == this.requestData.deliveryType) == undefined)
      {
      
this.requestData.deliveryType=0;

      }
    if(this.requestData.tempRequirementId!=0
      && !this.requestMaster.tempRequirementMaster.find((t:any)=>t.value==this.requestData.tempRequirementId))
    {
     this.requestData.tempRequirementId=0;
    }
    this.createRequest();
  }

  requestTypeChangeEvent(event:any){
    console.log(event);
  }
  
  bindConditionalFields(){
    console.log("bindConditionalFields");

  }
  onPlantSelectPopulate(event:any,index:number){
    console.log(event);

  }

}