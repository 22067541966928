import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:"root"
})
export  class ArticelManagementServices
{
    constructor(private client: HttpClient) { }


    retrieveArticlesProductList(requestedBy: number) {
        let link=environment.getArticleProductList.replace("{requestedby}", requestedBy.toString())
        return this.client.get(link).pipe(map((response) => response));
      }

      submitArticleMapping(data: any) {
        return this.client.post(environment.submitArticleProuctList, data).pipe(map((response) => response));
      }
}