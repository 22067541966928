import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { CleaningCategoryModel } from "../../cleaning-category/cleaning-category.model";
import { CleaningCategoryFormData } from "../../cleaning-category/data/cleaning-category-form.data";
import { ArticleManagementModel } from "../article-management.model";
import { ArticleMappingFormData } from "../data/article-management-form.data";


@Component({
  selector: 'app-admin-article-management',
  templateUrl: './admin-article-management.component.html',
 
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel, ArticleManagementModel, CleaningCategoryModel]
})

export class AdminArticleManagementComponent implements OnInit {

  @Input() loggedinUserinfo!: LoggecInUserInfo;

  articleMappingCollection: ArticleMappingFormData[] = []
  selectedMFG: any="";
  amEditing=false;
  mfgOpts: DropDownMaster[] = [];
  cleaningCategory:any;
  statusOpts: any;
  articleTableColumns: any;
  articleMappingData: ArticleMappingFormData = new ArticleMappingFormData();
  
  
  constructor(private articleModel: ArticleManagementModel,
    private cleaningCategoryModel: CleaningCategoryModel,
    private spinner: NgxSpinnerService,
    private toast: ToastrService) {

  }
  ngOnInit(): void {

    this.retrieveArticleStatus();
    this.retrieveArticleGridColumn();
    this.retrieveArticleMappingCollection();
    this.retrieveMFGMaster();
  }
  onRowAMEditInit(rowData:any) {
    this.articleMappingData.editing = true;
    this.articleMappingData.article_id = rowData.article_id;
    this.articleMappingData.article_number = rowData.article_number;
    this.articleMappingData.product_name = rowData.product_name;
    this.selectedMFG = rowData.mfg_code;
    this.articleMappingData.is_active = rowData.is_active;
  }
  onCancel()
  {

    this.articleMappingData=new ArticleMappingFormData();
    this.selectedMFG="";

  }

  updateArticleMapping()
  {  
        this.spinner.show();
        let artMappingCheck:any=this.articleMappingCollection.filter(am=>am.article_id!=this.articleMappingData.article_id)
        this.articleMappingData= this.articleModel.validateArticleForm(this.articleMappingData,this.selectedMFG)
        artMappingCheck.forEach((sc:any)=>{    
          if(this.articleMappingData.article_number==sc.article_number){
            this.articleMappingData.validArticleNumber=false;
            this.toast.warning("There is already an Article Number with this name.")          
          }
        })
        if (!(this.articleMappingData.validArticleNumber && this.articleMappingData.validProductName && this.articleMappingData.validMFG)) {
          this.spinner.hide();
          return false;
        }
        else{
        this.articleModel.onSave(this.articleMappingData,this.loggedinUserinfo.userId,this.selectedMFG,this.cleaningCategory)
        .subscribe({
          next:(saveResponse:any)=>
          {
            this.toast.success(saveResponse, "Success");
            this.retrieveArticleMappingCollection();
            this.onCancel();
            this.spinner.hide();
          }
          ,
          error:(error)=>
          {
            this.toast.error(error, "Error!");
            this.spinner.hide();
          }});
          return true;
        }
     
  }
  retrieveArticleStatus() {
    this.statusOpts = this.articleModel.retrieveArticleStatus();
  }
  retrieveArticleGridColumn() {
    this.articleTableColumns = this.articleModel.retrieveArticleGridColumn();
    const screenWidth = window.innerWidth;
    if(screenWidth < 750) {
      this.articleTableColumns.forEach((col:any) => {
          col.width = (parseInt(col.width.replace('%','')) * 10).toString() + 'px';
      });
    }
  }

  retrieveArticleMappingCollection() {
    this.articleModel.retrieveArticleMappingCollection(this.loggedinUserinfo.userId).subscribe({
    next: (articleMappingResponse: any) => {
      this.articleMappingCollection = articleMappingResponse;
    },
    error: (error: any) => {
      console.log('error ', error);
      
    },
  });
    
   
  }

  retrieveMFGMaster() {

    this.cleaningCategoryModel.retrieveCleaningCategoryCollection(this.loggedinUserinfo.userId).subscribe(CleaningCategoryresponse => {
      let resultCollection:CleaningCategoryFormData[]=CleaningCategoryresponse;
      this.cleaningCategory=resultCollection;
      resultCollection.forEach((element:any)=> {
        if(element.is_active){
          let result:DropDownMaster=new DropDownMaster();
          result.label=`${element.mfg_code}, ${element.mfg_description}`
          result.value=element.mfg_code;
          this.mfgOpts.push(result);   
        }
      });
    });
  }
} 
