import { ArticleMappingData } from "./article-management.data";

export class ArticleMappingFormData  extends ArticleMappingData
{
    edit:boolean=true;
    editing:boolean=false;
    status:string="";
    

    validArticleNumber:boolean=true;
    validProductName:boolean=true;
    validMFG:boolean=true;
}