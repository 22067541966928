import { DryTruckRequestFormData } from "./dry-request-form.data";

export class DryRequestDetails extends DryTruckRequestFormData {

    campaignSeasonalVolumeUidValue:string=""
    annualVolumeUnit: string="";
    monthlyVolumeUnit: string="";
    requestType: string="";
    transportTypeName: string="";
    specificEquipmentType:string="";
    
    reasonForSelection: string="";
    tempRequirement: string="";
    originPlantCode:string="";
    destinationPlantCode:string=""

    mandadoryDocsInsideTruckIdValue:string="";
    averageProductIdValue:string="";

    pumpName:string="";
    chassisName:string="";
    loadingPointName:string=""

    totalWeightShipmentUidValue:string="";

    rateReqCalData:any="";
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
}