import { Injectable } from "@angular/core";
import {  Observable,  Subject } from "rxjs";
import { ProductData } from "./Data/product.data";
import { ProductService } from "./product.service";

@Injectable()

export class ProductModel
{

    constructor(private productService:ProductService){}

    retrieveProductForArticle(articalNumber:string):Observable<ProductData>
    {
        let subject=new Subject<ProductData>();
        this.productService.retrieveProductInfoBasedonArticleNumber(articalNumber).subscribe(response=>
            {
                let apiResponse:any;
                apiResponse=response;
                let apiResponseStatus=apiResponse.results;
                if(apiResponseStatus.status_id===200)
                {
                    let data=this.mapProductData(apiResponse.ProductInfo);
                   if(data.articleNumber!=null && data.articleNumber!=undefined && data.articleNumber!="null") 
                   {
                    subject.next(data);
                   }
                   else

                   {
                    subject.error("INVALID_ARTICLE_NUMBER");
                   }
                }
                else
                {
                    subject.error("INVALID_ARTICLE_NUMBER");
                }

            })
        
    
        
        return subject.asObservable();
        
    }


    private mapProductData(response:any):ProductData
    {
        let data=new ProductData();
        data.articleNumber=response.article_number;
        data.friegeClass=response.freightClass;
        data.mfg=response.mfg_description;
        data.mfgCode=response.mfg_code;
        data.product=response.product_name;
        data.cleaningCategoryCode=response.cleaningCategoryCode;
        data.newRate=response.newRate;
        return data;
    }
}