import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DryTruckRequestFormData } from "src/app/requests/data/formdata/dry-request-form.data";
import { RequestMasterData } from "src/app/requests/data/masterdata/request-master.data";
import { DryTruckRequestFormModel } from "src/app/requests/dry-request-form.model";
import { DryTruckRequestMasterModel } from "src/app/requests/dry-request-master.model";
import { RequestFormModel } from "src/app/requests/request-form.model";
import { RequestMasterModel } from "src/app/requests/request-master.model";
import { ProductModel } from "src/app/shared/product/products.model";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { CommonRequestComponent } from "../../request-form-common.component";
import { TranslateService } from "@ngx-translate/core";
import { PlantCodeMasterData } from "../../../data/masterdata/request-master.data";



@Component(
  {
    selector: 'app-dry-request-emea-form',
    templateUrl: '..\\..\\..\\shared-templates\\request-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel, ProductModel,
      { provide: RequestFormModel, useClass: DryTruckRequestFormModel },
      { provide: RequestMasterModel, useClass: DryTruckRequestMasterModel }

    ]
  }
)

export class DryRequestFormEMEAComponent extends CommonRequestComponent implements OnInit
 {

  //  override requestMaster: RequestMasterData = new RequestMasterData;
  //  override requestData: DryTruckRequestFormData = new DryTruckRequestFormData;
  override requestData:any;
  override requestMaster:any;

    _toast: ToastrService;
    _translate:TranslateService
    constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
      private productModel: ProductModel, requestModel: RequestFormModel,
      toast: ToastrService, router: Router, spinner: NgxSpinnerService,
       private spinners: NgxSpinnerService, route: ActivatedRoute,translate:TranslateService) {
      super(userAccessModel, masterModel, requestModel, router, toast, spinner,route,translate);
      this._toast=toast;
      this._translate=translate;
    }    
  
  
  
    ngOnInit(): void {
      this.startDateLable="START_DATE";
      this.transportType = 2;
      this.requestMaster = new RequestMasterData();
      this.plantCodeShippingDetails=new PlantCodeMasterData();
      this.requestData = new DryTruckRequestFormData();
      this.requestData.transportType = 2;
      this.bindfiledVisiblity();
      this.retrieveHeaderValues();
      //login
      this.validateloggedInUser();
      //Request Master
      this.retrieveMasterData(this.transportType);
      //plantcode with shipping data
      this.retrievePlantCodeMasterData(this.transportType);
      this.retriveLang('English');
      //Article
      this.retrieveArticleList(this.spinners)
      //retrieve Query Parameters
      this.retrieveQueryPramaters();
  
    }
    public clearForm() {
      this.OnCancel();
      }
    bindfiledVisiblity()
    {
      this.fieldVisiblity.requestType = true;
      this.fieldVisiblity.startDate = true;
      this.fieldVisiblity.requestor = true;
      this.fieldVisiblity.bussinessunit = true;
      this.fieldVisiblity.articleNumber = true;
      this.fieldVisiblity.articleNumberMandatory = true;
      this.fieldVisiblity.product = true;
      this.fieldVisiblity.mfg = true;
      this.fieldVisiblity.mfgMandatory = true;
      this.fieldVisiblity.freightClass = false;
      this.fieldVisiblity.cleaninCost = false;
      this.fieldVisiblity.interModel = true;
      this.fieldVisiblity.equipmentType = true;
      // Service Provider
      this.fieldVisiblity.volumeType = true;
      this.fieldVisiblity.annualVolume=true;
      this.fieldVisiblity.nitrogenBlanket=true;
      this.fieldVisiblity.centerRearUnload = true;
      this.fieldVisiblity.centerRearUnloadMandatory = true;
      
      this.fieldVisiblity.priorProductRestriction = true;
      this.fieldVisiblity.kosher = true;
      this.fieldVisiblity.foodGradeWash = true;
      this.fieldVisiblity.unloadingHrs = true;
      this.fieldVisiblity.weekendDeliveryYN=true
      this.fieldVisiblity.dropTrail = true;
      this.fieldVisiblity.hazardousMandatory = true;
      this.fieldVisiblity.materialTopLoad = true;
      this.fieldVisiblity.loadingHrs = true;
      
      this.fieldVisiblity.specificEquipmentType=true;
      this.fieldVisiblity.pump = true;
      this.fieldVisiblity.chassis = true;
      this.fieldVisiblity.couplings = true;
      this.fieldVisiblity.loadingPoint =true;
      this.fieldVisiblity.gmp = true;
      this.fieldVisiblity.fami = true;
      this.fieldVisiblity.isopa = true;
      this.fieldVisiblity.halalWash = true;
      this.fieldVisiblity.unloadingHose = true;
      this.fieldVisiblity.onboardCompressor = true;
      this.fieldVisiblity.others = true;
    
    
      //Shipping->Orgin
      this.fieldVisiblity.originPlant=true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=true;
      this.fieldVisiblity.orginState=true;
      this.fieldVisiblity.orginCountry=true;
      this.fieldVisiblity.orginZipCode=true;
      //shipping-> destination
      this.fieldVisiblity.destinationPlant=true;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=true;
      this.fieldVisiblity.destinationState=true;
      this.fieldVisiblity.destinationCountry=true;
      this.fieldVisiblity.destinationZipCode=true;
  
      //Additional Info

      this.fieldVisiblity.comments=true;
  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;
      
    }
  
    override onArticleClear(event:any){
      this.resetArticleData();
    }
  
    onArticleComplete(event:any){
      super.retrieveProductDetails(event)
    }
    onArticleSelected(event:any){
      this.processArticleNumber(event);
  
    }
  
    retrieveHeaderValues() {
      this.title = "Dry Truck";
      this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
    }
    resetArticleData() {
      this.requestData.mfg = "";
      this.selectedMFGCode = "";
      this.requestData.freighClass = "";
      this.requestData.cleaningCost = "";
      this.selectedArticle = [];
      this.requestData.product = "";
      this.requestData.articleNumber = "";
  
    }
    GenerarateArticleNumberEvent() {
      this.selectedArticle.label = this.requestData.articleNumber;
      return {
        label: this.requestData.articleNumber
      };
    }
  
    handlehazardousChange(event:any)
    {
      console.log("")
    }
    processArticleNumber(event: any) {
      this.requestData.articleNumber = event.label;
      this.productModel.retrieveProductForArticle(event.label).subscribe({
        next:result => {
        this.requestData.articleNumber = result.articleNumber;
        this.requestData.cleaningCost = result.newRate;
        this.requestData.freighClass = result.friegeClass;
        this.requestData.mfg = result.mfg;
        this.requestData.product = result.product;
        this.selectedMFGCode = result.mfgCode;
      },error:error=>
      {
        this._toast.error(this._translate.instant(error),this._translate.instant("ERROR"))
      }
      });
  
    }
  
    override OnCancel() {
      
      this.clearData(2);
      this.requestData.articleNumber="";
      this.requestData.cleaningCost="";
      this.requestData.freighClass="";
      this.requestData.internModel = "";
      this.requestData.mfg="";
      this.requestData.campaignSeasonalVolume=0
      this.requestData.equipmentTypeId = 0;

    //Service provider
    this.requestData.days = 0;
    this.requestData.dropTrail = "";
    this.requestData.monthlyVolume = 0;
    this.requestData.monthlyVolumeUnitId = 0;
    this.requestData.centerRear = "";
    this.requestData.centerRearUnload = "";
    this.requestData.foodGradeWash = "";
    this.requestData.groundVapourDelivery = "";
    this.requestData.hazardous = "";
    this.requestData.kosher = "";
    this.requestData.loadingHours = ''
    this.requestData.materialTopLoad = "";
    this.requestData.nitrogenBlanket = "";
    this.requestData.priorProductRestriction = "";
    this.requestData.unloadingHour = '';
    this.requestData.weekendDelivery = "";
    this.requestData.pumpId=0;
    this.requestData.chassisId=0;
    this.requestData.couplings="";
    this.requestData.loadingPointId=0;
    this.requestData.gmp="";
    this.requestData.fami="";
    this.requestData.isopa="";
    this.requestData.halalWash="";
    this.requestData.unloadingHose="";
    this.requestData.onboardCompressor="";
    this.requestData.others="";
    this.resetArticleData();
    }
    onSubmitRequest() {
      this.handlingDefaultDropdownValues();
      this.requestData.equipmentTypeId=this.requestData.equipmentTypeId??0;
      this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;
      this.requestData.specificEquipmentTypeId=this.requestData.specificEquipmentTypeId??0;
      this.requestData.pumpId=this.requestData.pumpId??0;
      this.requestData.chassisId=this.requestData.chassisId??0;
      this.requestData.loadingPointId=this.requestData.loadingPointId??0;
  
      this.createRequest();
    }
    onSpecialRequirement(event:any){  }

    requestTypeChangeEvent(event:any){
      console.log('');
    }
    onAnnualVolumeChange(event: any){
      console.log('');
    }

    bindConditionalFields(){
      console.log("")  

    }
    onPlantSelectPopulate(event:any,index:number){
      console.log('')  

    }
  }