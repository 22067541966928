import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})

/** Retrieve the master data for the Request*/ 
export class RequestMasterService {

    constructor(private client: HttpClient) { }

    //Retrieve request master for the transport type
    retrieveRequestMaster(transportType: number) {
        return this.client.get(environment.getRequestMasterAPI + `/${transportType}`).pipe(map((response) => response));
    }

    retrieveRequestMasterPlantCodes(transportType:number,isOrigin:boolean,plantCode:string){
        let  api:string=environment.getRequestPlantCodesMaster.replace("{transportType}",String(transportType)).replace("{isOrigin}",String(isOrigin)).replace("{plantCode}",String(plantCode))
        return this.client.get(api).pipe(map((response) => response));
    }
    retrievePlantCodebyMasterId(transportType:number,plantCodeId:number){
        let  api:string=environment.getPlantCodeByMasterId.replace("{transportType}",String(transportType)).replace("{plantCodeId}",String(plantCodeId))
        return this.client.get(api).pipe(map((response) => response));
    }

    //Retrieve Article number list 
    retrieveRequestArticleMaster() {
        return this.client.get(environment.getRequestArticleMaster).pipe(map((response) => response));
    }

}